import React from 'react';
import { Group, Loader, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

interface HoverTooltipProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  onOpen?: () => void;
}

export const HoverTooltip: React.FC<HoverTooltipProps> = ({ content, children, onOpen }) => {
  const [opened, { close, open }] = useDisclosure(false);

  const getContent = () => {

    if (onOpen && !content) {
      return <Group p={'xl'} justify='center'><Loader /></Group>;
    }

    if ((typeof content === 'string' || content instanceof String)) {
      return <Text size="sm" p={'sm'} fw={500}>{content}</Text>;
    } else {
      return content;
    }
  };

  return (
    <Popover position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <div onMouseEnter={() => { open(); if (onOpen) onOpen(); }} onMouseLeave={close}>
          {children}
        </div>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        {getContent()}
      </Popover.Dropdown>
    </Popover>
  );
};
