import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Space,
  Tabs,
  Group,
  Text,
  Box,
  useMantineTheme,
  Popover,
  Flex,
} from '@mantine/core';

import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { FormBuilder } from '../../components/formbuilder/FormBuilder';
import { FormRunner } from '../../components/formbuilder/FormRunner';
import { isPresent } from 'utilitype';
import { t } from 'ttag';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';
import { FormElementMode } from '../../components/formbuilder/FormElementMode';
import { useDisclosure } from '@mantine/hooks';
import { createStyles } from '@mantine/emotion';

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  content: {
    width: '100%',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing.xl,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '24px',
    marginRight: theme.spacing.xl,
    boxShadow: `0 -5px 3px -3px ${theme.colors.gray[5]}`,
    zIndex: 20,
  },
}));
interface ApplicationPeriodBuilderModalProps {
  applicationPeriod: ApplicationPeriod;
  formData: string | undefined;
  onDone: () => void;
}

export interface FormStatus {
  missingElements: string[];
  missingInputTypes: string[];
}

export const ApplicationPeriodFormBuilderModal: React.FC<ApplicationPeriodBuilderModalProps> = ({
  applicationPeriod,
  formData,
  onDone,
}) => {
  const api = useApi();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { selectedOrganisation } = useSession();
  const [json, setJson] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>('builder');
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [showFormErrors, { open, close }] = useDisclosure(false);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrganisation) {
        if (formData) {
          setJson(formData);  
        } else {
          const result = await api.getApplicationPeriodForm(
            selectedOrganisation.id,
            applicationPeriod.id,
          );
          setJson(JSON.stringify(result));
        }
      }
    };

    if (isPresent(selectedOrganisation && isPresent(applicationPeriod))) {
      fetchData();
    }
  }, [applicationPeriod]);

  const onJsonChanged = (data: string) => {
    setJson(data);
  };

  const save = async (closeOnSave: boolean) => {
    if (!selectedOrganisation || !json) {
      return;
    }

    try {
      await api.updateApplicationPeriodForm(
        selectedOrganisation.id,
        applicationPeriod.id,
        JSON.parse(json),
      );
      showSuccessNotification(t`Klart`, t`Formulär sparat`);

      if (closeOnSave) {
        onDone();
      }
    } catch {
      showFailNotification(t`Fel`, t`Något gick fel vid sparning av formulär`);
    }
  };

  if (!json) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const getFormStatus = () => {
    if (formErrors) {
      const isValid = formErrors.length === 0;
      if (isValid) {
        return <IconCircleCheck size={36} color={theme.colors.green[5]} />;
      } else {
        return (
          <Popover width={360} position={'top'} withArrow shadow={'md'} opened={showFormErrors}>
            <Popover.Target>
              <Box w={36} h={36}>
                <IconCircleX
                  onMouseEnter={open}
                  onMouseLeave={close}
                  size={36}
                  color={theme.colors.red[5]}
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Flex gap={16} direction={'column'} align={'center'}>
                {formErrors.length > 0 && (
                  <Flex direction={'column'} align={'center'}>
                    <Text
                      ta={'center'}
                      size={'sm'}
                      fw={'bold'}>{t`Element som saknas`}</Text>
                    {formErrors.map((e) => {
                      return (
                        <Text color={'red.5'} size={'sm'} fw={500} key={'error_' + e}>
                          {e}
                        </Text>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            </Popover.Dropdown>
          </Popover>
        );
      }
    }
  };

  const formIsValid = formErrors.length === 0;

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.content}
        style={{ marginBottom: activeTab === 'preview' ? '100px' : 0 }}>
        {/* <Tabs value={activeTab} onTabChange={setActiveTab}> */}
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="builder">Formulär</Tabs.Tab>
            <Tabs.Tab value="preview">Förhandsgranska</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="builder" pt="lg">
            <Stack>
              <FormBuilder
                json={json}
                onChanged={onJsonChanged}
                applicationPeriod={applicationPeriod}
                setFormStatus={setFormErrors}
              />
              <Space h={32} />
            </Stack>
          </Tabs.Panel>

          <Tabs.Panel value="preview" pt="lg">
            <FormRunner
              runnerMode={FormElementMode.RunnerPreview}
              json={json}
              applicationPeriod={applicationPeriod}
              moveUpNavigation={92}
            />
          </Tabs.Panel>
        </Tabs>
      </div>
      <div className={classes.footer}>
        <Group justify={'space-between'} w={'100%'} align={'center'}>
          <Button
            disabled={!formIsValid}
            type="submit"
            onClick={async () => {
              await save(true);
            }}>
            {t`Spara och stäng`}
          </Button>
          {getFormStatus()}
          <Group justify={'end'}>
            <Button variant="outline" onClick={() => onDone()}>
              {t`Avbryt`}
            </Button>
            <Button
              disabled={!formIsValid}
              type="submit"
              onClick={async () => {
                await save(false);
              }}>
              {t`Spara`}
            </Button>
          </Group>
        </Group>
      </div>
    </div>
  );
};
