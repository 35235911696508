/* eslint-disable @typescript-eslint/no-unused-vars */
import { IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import React from 'react';
import { RenderDatesCell } from './RenderDatesCell';
import { t } from 'ttag';
import { RenderSADCells } from './RenderSADCells';
import { CellRenderInformation } from '../../../components/LiitGrid/RenderCell';
import { Flex, Group, Stack, Text } from '@mantine/core';
import Formatter from '../../../utils/formatter';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ApprovalApplicationPeriodListColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Ansökningsperiod',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { width: 'auto', paddingLeft: 40 },
    renderCell: (rowInformation: CellRenderInformation) => {
      const { rowValues } = rowInformation;
      return (
        <Flex direction={'column'}>
          <Text fz={14}>{rowValues.title}</Text>
          <Group gap="xs">
            <Text fz={14} c="dimmed">
              {Formatter.formatDateString(rowValues.startDate)} -{' '}
              {rowValues.endDate ? Formatter.formatDateString(rowValues.endDate) : 'tills vidare'}
            </Text>
          </Group>
        </Flex>
      );
    },
  },
  // {
  //   displayName: t`I/B/N`,
  //   propertyName: 'numberOfSubmittedApplications',
  //   propertyType: PropertyType.Number,
  //   renderCell: RenderSADCells,
  //   columnStyles: { whiteSpace: 'nowrap', width: '140px', cursor: 'pointer', userSelect: 'none' },
  // },
  {
    displayName: 'Inlämnade',
    propertyName: 'numberOfSubmittedApplications',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Beviljade',
    propertyName: 'numberOfApprovedApplications',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Nekade',
    propertyName: 'numberOfDeclinedApplications',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Sökt belopp',
    propertyName: 'requestedAmount',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Beviljat belopp',
    propertyName: 'grantedAmount',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Budget',
    propertyName: 'budget',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
];
