import { Group, ActionIcon, useMantineTheme, Popover, Text } from '@mantine/core';
import { IconArrowsRightLeft, IconAsterisk, IconQuestionMark } from '@tabler/icons-react';
import React from 'react';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';

interface FormLabelProps {
  element: FormElement;
  mode: FormElementMode;
  justify: string;
}

export const FormLabel: React.FC<FormLabelProps> = ({ element, mode, justify }) => {
  const theme = useMantineTheme();
  const { label, required, hideLabel, helpText, labelAndTextPosition } = element;

  if (hideLabel) {
    return null;
  }

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);

  const allowReversedLabel =
    mode === FormElementMode.Runner || mode === FormElementMode.RunnerPreview;
    
  return (
    <Group
      gap={'xs'}
      justify={justify}
      w={'100%'}
      style={{
        flexDirection:
          allowReversedLabel && labelAndTextPosition === 'right' ? 'row-reverse' : 'row',
      }}>
      <Group
        gap={4}
        style={{ flexDirection: labelAndTextPosition === 'right' ? 'row-reverse' : 'row' }}>
        {mode === FormElementMode.Editor && labelAndTextPosition === 'right' && (
          <IconArrowsRightLeft size={14} />
        )}
        {labelText}
        {required && <IconAsterisk color={theme.colors.red[5]} size={10} />}
      </Group>
      {mode !== FormElementMode.Editor && helpText && (
        <Popover position={'top'} withArrow shadow="md">
          <Popover.Target>
            <ActionIcon
              size={16}
              radius={8}
              color={theme.colors[theme.primaryColor][7]}
              variant={'outline'}>
              <IconQuestionMark size={12} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <Text
              fw={400}
              c={theme.colors.dark[9]}
              size="xs"
              style={{ whiteSpace: 'pre-wrap', maxWidth: '400px' }}>
              {LanguageHandler.getTextByLanguage(helpText, LanguageCode.sv)}
            </Text>
          </Popover.Dropdown>
        </Popover>
      )}
    </Group>
  );
};
