import React from 'react';
import { Button, Grid, Group, Space, Stack, Switch, Text, TextInput } from '@mantine/core';
import { useSession } from '../../hooks/useSession';
import { PageView } from '../../layout/PageView';
import { useForm } from '@mantine/form';
import { useApi } from '../../hooks/useApi';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';
import { t } from 'ttag';

interface FormValues {
  firstName: string;
  lastName: string;
  isReviewReminderEnabled: boolean;
}

export const UserProfileView: React.FC = () => {

  const { user, refreshSession } = useSession();
  const api = useApi();

  const form = useForm<FormValues>({
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      isReviewReminderEnabled: user?.isReviewReminderEnabled ?? false,
    },

    validate: {
      firstName: (value) => (value ? null : 'Ange ett förnamn'),
      lastName: (value) => (value ? null : 'Ange ett efternamn'),
    },
  });

  const saveChanges = async (values: FormValues) => {
    const response = await api.updateUserProfile(values.firstName, values.lastName, values.isReviewReminderEnabled);
    if (response.ok) {
      showSuccessNotification(t`Inställningarna uppdaterade`, t`Inställningarna har uppdaterats`);
      if (refreshSession) {
        await refreshSession();
      }
    } else {
      showFailNotification(t`Något gick fel`, t`Något gick fel vid uppdatering, var god försök senare`);
    }

    form.resetDirty();
  };

  return (

    <PageView>
      <PageView.Header>
        {t`Inställningar`}
      </PageView.Header>
      <PageView.Body enableMaxWidth>

        <form onSubmit={form.onSubmit(async (values) => saveChanges(values))}>
          <Grid>
            <Grid.Col span={3}><b>{t`Förnamn`}</b></Grid.Col>
            <Grid.Col span={9}><TextInput{...form.getInputProps('firstName')} /></Grid.Col>

            <Grid.Col span={3}><b>{t`Efternamn`}</b></Grid.Col>
            <Grid.Col span={9}><TextInput{...form.getInputProps('lastName')} /></Grid.Col>

            <Grid.Col span={3}><b>{t`E-post`}</b></Grid.Col>
            <Grid.Col span={9}>{user?.email}</Grid.Col>

            <Grid.Col span={12} >
              <Space h={'xl'} />
              <Switch label={t`Skicka e-post med ej granskade ansökningar`}
                description={t`En gång per vecka skickas en sammanställning av nyinkomna ansökningar som du ännu inte har granskat. Gäller enbart för de perioder som du har rätt att granska.`}
                {...form.getInputProps('isReviewReminderEnabled', {
                  type: 'checkbox',
                })} />
            </Grid.Col>

            <Grid.Col span={12}>
              <Space h={'xl'} />
              <Group justify="right" mt="md">
                <Button disabled={!form.isDirty()} type="submit">{t`Spara`}</Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>

      </PageView.Body>
    </PageView>
  );
};
