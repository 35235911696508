import { Stack, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { ApprovalApplicationPeriodListColumns } from './ApprovalApplicationPeriodListColumns';

export const ApprovalApplicationPeriodList: React.FC = () => {
  const [periods, setPeriods] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { selectedOrganisation } = useSession();
  const api = useApi();

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      const periodResult = await api.getApprovalPeriods(selectedOrganisation.id);
      setPeriods(periodResult);
      setIsFetching(false);
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation]);

  return (
    <GrantMeContainer>
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Title order={3}>Besluta</Title>
        </GrantMeTitleContainer>

        <LiitGrid
          data={periods}
          columnInfo={ApprovalApplicationPeriodListColumns()}
          onRowClick={(id: string) => {
            navigate(`/approvals/${id}`);
          }}
          highligthOnHoover
          fetchingData={isFetching}
        />
      </Stack>
    </GrantMeContainer>
  );
};
