import { Anchor, Group, Text } from '@mantine/core';
import { FC, ReactElement } from 'react';
import { isMissing } from 'utilitype';
import Formatter from '../../../../utils/formatter';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { TextBoxInputType } from './TextBoxInputType';

export const ViewerTypedTextBox: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const typedElement = element as FormElementTypedTextBox;
  const { inputType, labelAndTextPosition } = typedElement;

  const formattedText = (): string => {
    if (isMissing(element.value)) {
      return '';
    }

    if (inputType === TextBoxInputType.RequestedAmount || inputType === TextBoxInputType.Number) {
      const toFormat = typeof(typedElement.value) === 'number' ? typedElement.value : Number(typedElement.value);
      return Formatter.formatNumber(toFormat);
    }

    return typedElement.value as string;
  };

  const isUrl = (text: string) => {
    const includeRegex = /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    if (includeRegex.test(text)) {
      return true;
    }

    return false;
  };

  const getTextElements = (text: string) => {

    const elements = new Array<ReactElement>();

    if (inputType === TextBoxInputType.Plain) {
      const words = new Array<string>();

      text.split(' ').forEach((word, index) => {
        if (isUrl(word)) {
          if (words.length > 0) {
            elements.push(<Text size={'sm'} key={`${element.id}_text_${index}`}>{words.join(' ')}</Text>);
            words.length = 0;
          }
          elements.push(<Anchor size={'sm'} key={`${element.id}_link_${index}`} href={word} target='_blank'>{word}</Anchor>);
        } else {
          words.push(word);
        }    
      });
  
      if (words.length > 0) {
        elements.push(<Text key={`${element.id}_text_final`} size={'sm'}>{words.join(' ')}</Text>);
      }
    } else {
      elements.push(<Text key={`${element.id}_text_final`} size="sm">{text}</Text>);
    }

    return elements;
  };
 
  return (
    <ViewerInputWrapper number={number} element={element}>
      <Group p={0} gap={3} style={{ whiteSpace: 'pre-wrap' }} ta={labelAndTextPosition === 'right' ? 'right' : 'left'}>
        {getTextElements(formattedText())}
      </Group>
    </ViewerInputWrapper>
  );
};
