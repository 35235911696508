import {
  Group,
  Paper,
  Stack,
  Title,
  TypographyStylesProvider,
  Checkbox,
  Button,
  Divider,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { showFailNotification } from '../../utils/notificationHelper';
import { createStyles } from '@mantine/emotion';

const useAgreementStyles = createStyles((theme) => ({
  flexSelf: {
    flex: '1 1 auto',
  },
  scrollContainer: {
    // flex: 1,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    // paddingTop: 24,
    // paddingBottom: 24,
    padding: 24,
    overflow: 'auto',
    flexDirection: 'row',
    backgroundColor: theme.colors.gray[1],
    flex: 1,
  },
  formContainer: {
    maxWidth: '800px',
    minWidth: '600px',
    padding: 16,
    paddingTop: 0,
    border: `1px solid ${theme.colors.gray[5]}`,
  },

  documentStyles: {
    '& * h1': {
      color: theme.colors[theme.primaryColor][7],
      marginTop: 0,
    },
  },
}));

export enum AgreementType {
  GDPR = 0,
  UserAgreement = 1,
}

const AgreementTypeInfo: Record<AgreementType, string> = {
  [AgreementType.GDPR]: t`Avtal för GDPR måste gordkännas`,
  [AgreementType.UserAgreement]: t`Det nya användaravtalet måste godkännas`,
};

export interface AgreementItem {
  id: string;
  agreementText: string;
  type: AgreementType;
}

export const Agreement: React.FC = () => {
  const { classes, theme } = useAgreementStyles();
  const [agreement, setAgreement] = useState<AgreementItem>();
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

  const api = useApi();

  useEffect(() => {
    const fetch = async () => {
      const result = await api.getUnapprovedAgreement();
      setAgreement(result);
    };

    fetch();
  }, []);

  if (!agreement) {
    return <div></div>;
  }

  const approveAgreement = async () => {
    const result = await api.approveAgreement(agreement.id);
    if (result.ok) {
      window.location.reload();
    } else {
      showFailNotification(t`Något gick fel`, t`Något gick fel när avtalet godkändes. Var god försök igen`);
    }

  };

  return (
    <GrantMeContainer style={{ overflow: 'hidden' }}>
      <Stack gap={0} style={{ flex: 1, overflow: 'hidden' }}>
        <GrantMeTitleContainer>
          <Group
            justify={'space-between'}
            wrap={'nowrap'}
            style={{ flex: '1 1 auto', minWidth: 0, overflow: 'hidden' }}>
            <Title
              order={3}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {AgreementTypeInfo[agreement.type]}
            </Title>
          </Group>
        </GrantMeTitleContainer>
        <div className={classes.scrollContainer}>
          <Paper w={900} p={40}>
            <TypographyStylesProvider
              className={classes.documentStyles}
              style={{ color: theme.black }}>
              <div dangerouslySetInnerHTML={{ __html: agreement.agreementText ?? '' }} />
            </TypographyStylesProvider>
            <Divider mb={24} />
            <Stack justify={'center'} align={'center'}>
              <Checkbox checked={checkboxChecked} onChange={() => setCheckboxChecked(prev => !prev)} label={t`Jag godkänner avtalet`} />
              <Button disabled={!checkboxChecked} onClick={approveAgreement} fullWidth={false}>{t`Godkänn`}</Button>
            </Stack>
          </Paper>
        </div>
      </Stack>
    </GrantMeContainer>
  );
};
