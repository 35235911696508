import { Button, Card, Group, Space, Stack, Title, Tabs } from '@mantine/core';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { LiitTabs } from '../../components/LiitTabs/LiitTabs';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import {
  ApplicationReportBaseColumns,
  ApplicationReportSubmittedListColumns,
} from './ApplicationReportListColumns';

export const ApplicationReportList: React.FC = () => {
  const [reports, setReports] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { selectedOrganisation } = useSession();
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      const result = await api.getApplicationReports(selectedOrganisation.id);
      setReports(result);

      setIsFetching(false);
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation]);

  const submittedReports = reports.filter((x) => x.status == ApplicationStatus.Submitted);
  const upcomingReports = reports.filter(
    (x) => x.status == ApplicationStatus.Draft && x.isOverdue == false,
  );
  const overdueReports = reports.filter(
    (x) => x.status == ApplicationStatus.Draft && x.isOverdue == true,
  );

  return (
    <GrantMeContainer>
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Title order={3}>Återrapporter</Title>
        </GrantMeTitleContainer>
        <LiitTabs defaultValue={'submitted'} fixedTabWith={150}>
          <Tabs.List>
            <Tabs.Tab value="submitted">Inlämnade</Tabs.Tab>
            <Tabs.Tab value="upcoming">Kommande</Tabs.Tab>
            <Tabs.Tab value="overdue">Förfallna</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="submitted">
            <LiitGrid
              data={submittedReports}
              columnInfo={ApplicationReportSubmittedListColumns()}
              onRowClick={(id: string) => {
                navigate(`/applicationreports/${id}`);
              }}
              highligthOnHoover
              fetchingData={isFetching}
            />
          </Tabs.Panel>
          <Tabs.Panel value="upcoming">
            <LiitGrid
              data={upcomingReports}
              columnInfo={ApplicationReportBaseColumns()}
              highligthOnHoover={false}
              fetchingData={isFetching}
            />
          </Tabs.Panel>
          <Tabs.Panel value="overdue">
            <LiitGrid
              data={overdueReports}
              columnInfo={ApplicationReportBaseColumns()}
              highligthOnHoover={false}
              fetchingData={isFetching}
            />
          </Tabs.Panel>
        </LiitTabs>
      </Stack>
    </GrantMeContainer>
  );
};
