import { addLocale, useLocale } from 'ttag';
import * as cookie from './cookie';
import { Locals, GrantMeLocal } from './locals';
import { isPresent } from 'utilitype';

const LOCALE_COOKIE = '__locale';

export const getLocale = (): GrantMeLocal => {
  const activeLocale = cookie.get(LOCALE_COOKIE);
  return isPresent(activeLocale) ? Locals[activeLocale] : Locals.sv;
};

export function saveLocale(locale) {
  cookie.set(LOCALE_COOKIE, locale.name);
}

export const setLocale = async (forced?: GrantMeLocal) => {
  if (forced) {
    saveLocale(forced);
  }
  const locale = getLocale();

  if (locale.name !== 'sv') {
    (async () => {
      const translationsObj = await require(`../i18n/${locale.Locale}.po.json`);
      addLocale(locale.Locale, translationsObj);
      useLocale(locale.Locale);
    })();
  }
};

