import { Text, Table } from '@mantine/core';
import { ReactNode } from 'react';
import { t } from 'ttag';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import Formatter from '../../../utils/formatter';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { HoverTooltip } from '../../../components/HoverTooltip';

export const RenderAverageCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { rowValues, theme } = rowInformation;
  const { id, reviewTotalAverage, reviewDetails, reviewCompletedCount } = rowValues;

  const api = useApi();
  const { selectedOrganisation } = useSession();

  const getAverageScorePerCriteria = async () => {

    if (reviewDetails)
      return;

    const response = await api.getReviewDetails(selectedOrganisation!.id, id);

    rowInformation.setData((prev) => {
      const app = prev.find((x) => x.id === rowValues.id);
      app.reviewDetails = response; 
      const newPrev = [...prev];
      return newPrev;
    });

    if (rowInformation.onCellValueChanged) {
      rowInformation.onCellValueChanged();
    }
  };

  const getAverageToolTip = () => {    
    if (!reviewDetails) {
      return null;
    }

    if (reviewDetails.scores.length === 0) {

      if (reviewCompletedCount > 0) {
        return (
          <Text p={16} size={'xs'} fw={500}>
            {t`Inga poäng utdelade på denna ansökan`}
          </Text>
        );
      } else {
        return (
          <Text p={16} size={'xs'} fw={500}>
            {t`Inga granskningar gjorda på denna ansökan`}
          </Text>
        );
      }
    }

    const included = reviewDetails.scores.filter(x => !x.isExcluded);
    const excluded = reviewDetails.scores.filter(x => x.isExcluded);

    return (
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <Text size={'xs'} fw={600}>{t`Kriterie`}</Text>
            </Table.Th>
            <Table.Th>
              <Text size={'xs'} fw={600}>{t`Medel`}</Text>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {included.map((c) => (
            <Table.Tr key={`r_${c.criteriaTitle}`}>
              <Table.Td>
                <Text p={0} size={'xs'} fw={500}>
                  {c.criteriaTitle}
                </Text>
              </Table.Td>
              <Table.Td>
                <Text p={0} size={'xs'} fw={'bold'}>
                  {Formatter.formatNumber(c.average) ?? '-'}
                </Text>
              </Table.Td>
            </Table.Tr>
          ))}
          {excluded.length > 0 && (
            <>
            <Table.Tr>
              <Table.Td colSpan={2} style={{ paddingBottom: '2px', border: 0,  borderBottom: `1px solid ${theme.colors.gray[3]}` }}>
                <Text size={'xs'} fw={'bold'} color={theme.colors[theme.primaryColor][7]}>
                  {t`Exkluderade kriterier`}
                </Text>
              </Table.Td>
            </Table.Tr>
            {excluded.length > 0 && excluded.map((c) => 
              <Table.Tr key={`r_excluded_${c.criteriaId}`}>
                <Table.Td>
                <Text p={0} size={'xs'} fw={500} color={'dimmed'}>
                  {c.criteriaTitle}
                </Text>
              </Table.Td>
              <Table.Td>
                <Text p={0} size={'xs'} fw={'bold'} color={'dimmed'}>
                {Formatter.formatNumber(c.average) ?? '-'}
                </Text>
              </Table.Td>
              </Table.Tr>)}  
            </>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const roundedTotal = reviewTotalAverage
    ? Formatter.formatNumber(Math.round((reviewTotalAverage + Number.EPSILON) * 100) / 100)
    : null;

  return (
    <HoverTooltip content={getAverageToolTip()} onOpen={() => getAverageScorePerCriteria()} >
        <Text size="sm">{roundedTotal ?? '-'}</Text>
    </HoverTooltip>
  );
};
