import { Button, Grid, Space, Stack, Stepper, Title, Text, Center } from '@mantine/core';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { FormElement } from './models/FormElement';
import { FormModel } from './models/FormModel';
import { FormElementComponent } from './FormElementComponent';
import { FormElementMode } from './FormElementMode';
import { FormPage } from './models/FormPage';
import { FormViewer } from './FormViewer';
import { FormElementType } from './models/FormElementType';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { elementValidators } from './ElementValidators';
import { validateRepeater } from './FormElements/Repeater/validateRepeater';
import { repeaterElementPath } from './FormElements/Repeater/repeaterElementPath';
import { RepeaterRunnerModel } from './FormElements/Repeater/repeaterRunnerModel';
import { FormFileUpload } from './models/FormFileUpload';
import { FormFileDelete } from './models/FormFileDelete';
import { UploadedFile } from '../../models/UploadedFile';
import { FormRunnerStepsControls } from './FormRunnerStepControls';
import { ApplicationDetails } from '../../models/ApplicationDetails';
import LanguageHandler from '../../utils/languageHandler';
import { LanguageCode } from '../../models/LanguageCode';
import { ApplicantFormFields } from './models/ApplicantFormFields';

interface RunnerProps {
  runnerMode: FormElementMode.Runner | FormElementMode.RunnerPreview;
  application?: ApplicationDetails;
  applicationPeriod: ApplicationPeriod;
  json: string;
  onCompleted?: (json: string) => void;
  onFileUpload?: (fileUpload: FormFileUpload) => Promise<UploadedFile | undefined>;
  onFileDelete?: (file: FormFileDelete) => Promise<void>;
  moveUpNavigation?: number;
}

// TODO: Use https://mantine.dev/form/use-form/#useformreturntype instead?

export const FormRunner: FC<RunnerProps> = ({
  runnerMode,
  json,
  onCompleted,
  applicationPeriod,
  onFileUpload,
  onFileDelete,
  moveUpNavigation,
}) => {
  const [active, setActive] = useState(0);

  const form = useForm({
    initialValues: {
      ...({ pages: [] } as FormModel),
    },
  });

  useEffect(() => {
    const result: FormModel = JSON.parse(json);
    form.setValues(result);
  }, [json]);

  const renderElement = (
    element: FormElement,
    pageIndex: number,
    index: number,
    questionNumber: number,
  ) => {
    const onChange = (value: unknown) => {
      if (element.type === FormElementType.FileGroup) {
        if (onFileUpload && value instanceof FormFileUpload) {
          onFileUpload(value)
            .then((uploadedFile) => {
              form.setFieldValue(`pages.${pageIndex}.elements.${index}.value`, uploadedFile);
            })
            .catch(() => {
              form.setFieldError(
                `pages.${pageIndex}.elements.${index}.value`,
                'Filen har ogiltigt format. Giltiga format är JPEG, PDF och PNG.',
              );
            });
        } else if (onFileDelete && value instanceof FormFileDelete) {
          onFileDelete(value)
            .then(() => {
              form.setFieldValue(`pages.${pageIndex}.elements.${index}.value`, null);
              form.clearFieldError(`pages.${pageIndex}.elements.${index}.value`);
            })
            .catch(() => {
              form.setFieldError(
                `pages.${pageIndex}.elements.${index}.value`,
                'Kunde inte ta bort filen',
              );
            });
        }
      } else {
        form.setFieldValue(`pages.${pageIndex}.elements.${index}.value`, value);
      }
    };

    return (
      <FormElementComponent
        number={questionNumber}
        element={element}
        mode={runnerMode}
        error={form.errors[`pages.${pageIndex}.elements.${index}.value`]}
        onChange={(value) => onChange(value)}
        repeaterRunnerInformation={
          element.type === FormElementType.Repeater
            ? ({
              form: form,
              pageIndex: pageIndex,
              elementIndex: index,
              onFileUpload: onFileUpload,
              onFileDelete,
            } as RepeaterRunnerModel)
            : null
        }
      />
    );
  };

  const renderPage = (page: FormPage, pageIndex: number, startQuestionNumber: number) => (
    <React.Fragment key={`runner_page_${page.id}`}>
      {page.elements &&
        page.elements.map((element, index) => {
          return (
            <Grid.Col span={{ xs: 12, sm: element.size }} key={`element_${element.id}_${index}`}>
              {renderElement(element, pageIndex, index, startQuestionNumber + index)}
            </Grid.Col>
          );
        })}
    </React.Fragment>
  );

  const isPageValid = (pageIndex: number) => {
    let hasErrors = false;
    const page = form.values.pages[pageIndex];
    for (const elementIndex in page.elements) {
      const element = page.elements[elementIndex];

      if (element.type === FormElementType.Repeater) {
        const { isValid, errorMessage, validationElements } = validateRepeater(element);
        if (!isValid) {
          form.setFieldError(`pages.${pageIndex}.elements.${elementIndex}.value`, errorMessage);
          hasErrors = true;
        }

        for (const e in validationElements) {
          const validation = validationElements[e];
          if (!validation.validationResult.isValid) {
            const {
              groupIndex,
              elementIndex: eIndex,
              validationResult: { errorMessage: eMessage },
            } = validation;
            const path = repeaterElementPath(pageIndex, Number(elementIndex), groupIndex, eIndex);
            form.setFieldError(path, eMessage);
            hasErrors = true;
          }
        }
      } else {
        const validator = elementValidators[element.type];
        if (validator) {
          const { isValid, errorMessage } = validator(element);
          if (!isValid) {
            form.setFieldError(`pages.${pageIndex}.elements.${elementIndex}.value`, errorMessage);
            hasErrors = true;
          }
        }
      }
    }

    return !hasErrors;
  };

  const submit = () => {
    const result = form.validate();
    let { hasErrors } = result;

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const pageIndex in form.values.pages) {
      const isValidPage = isPageValid(parseInt(pageIndex));

      if (isValidPage) {
        hasErrors = !isValidPage;
      }
    }
    if (!hasErrors && onCompleted) {
      onCompleted(JSON.stringify(form.values, null, 4));
    }
  };

  const numberOfPages = form.values.pages.length + 1;

  const nextStep = () => {
    const pageIsValid = isPageValid(active);
    if (pageIsValid || runnerMode === FormElementMode.RunnerPreview) {
      setActive((current) => (current < numberOfPages ? current + 1 : current));
    }
  };

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const renderForm = () => {
    let startQuestionNumber = 0;

    if (form && form.values.pages) {
      return (
        <>
          <Stepper iconSize={32} active={active} onStepClick={setActive} size="sm">
            {form.values.pages.map((page, pageIndex) => {
              const elements = (
                <Stepper.Step
                  key={`step_${page.id}`}
                  label={LanguageHandler.getTextByLanguage(page.label, LanguageCode.sv)}
                  description="">
                  {page.label && (
                    <>
                      <Title order={4}>
                        {LanguageHandler.getTextByLanguage(page.label, LanguageCode.sv)}
                      </Title>
                      <Space h={'lg'} />
                    </>
                  )}

                  <Grid gutter={'lg'}>{renderPage(page, pageIndex, startQuestionNumber)}</Grid>
                </Stepper.Step>
              );
              startQuestionNumber += page.elements.length;
              return elements;
            })}
            <Stepper.Completed>
              <Stack>
                <Title size="h4">Klart!</Title>
                <Text>
                  Granska dina uppgifter och klicka därefter på knappen nedan för att lämna in din
                  ansökan.
                </Text>
                <FormViewer json={JSON.stringify(form.values, null, 4)} showPageLabels={false} />
              </Stack>
              {onCompleted && (
                <Center>
                  <Button m="xl" onClick={() => submit()}>
                    Lämna in ansökan
                  </Button>
                </Center>
              )}
            </Stepper.Completed>
          </Stepper>
          <Space h="xl" />
          <FormRunnerStepsControls
            onNext={nextStep}
            onPrev={prevStep}
            active={active}
            numberOfPages={numberOfPages}
            moveUpNavigation={moveUpNavigation}
          />

          <Space h="xl" />
        </>
      );
    }

    return <></>;
  };

  return <Stack style={{ overflowX: 'hidden' }}>{renderForm()}</Stack>;
};
