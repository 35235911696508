import { Box, Group, Menu, ScrollArea, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronDown, IconFilter } from '@tabler/icons-react';
import React from 'react';

const useStyles = createStyles((theme) => ({
  menuItemWrapper: {
    backgroundColor: theme.white,
    cursor: 'pointer',
    absolute: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },

  truncate: {
    maxWidth: '240px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      maxWidth: '140px',
    },
  },
}));

export interface LiitDropdownMenuItem {
  id: string;
  text: string;
  color?: string;
  inverted?: string;
}

interface LiitDropdownProps {
  emptyText?: string;
  menuItems: LiitDropdownMenuItem[] | null;
  selectedId?: string | null;
  onChange: (periodId: string | null) => void;
  showColorBox?: boolean;
}

export const LiitDropdown: React.FC<LiitDropdownProps> = ({
  emptyText,
  menuItems,
  selectedId,
  onChange,
  showColorBox = false,
}) => {
  const { classes, theme } = useStyles();
  const largeScreen = useMediaQuery('(min-width: 1200px)');

  if (menuItems === null) {
    return null;
  }

  const getText = () => {
    return selectedId === null
      ? emptyText
      : menuItems.find((p) => p.id === selectedId)?.text ?? emptyText;
  };

  const getColor = () => {
    return selectedId === null ? theme.white : menuItems.find((p) => p.id === selectedId)?.color;
  };

  return (
    <div className={classes.menuItemWrapper}>
      <Menu
        transitionProps={{ transition: 'pop' }}
        position={'bottom-start'}
        styles={{
          item: {
            padding: '6px 12px',
          },
          dropdown: {
            maxHeight: '370px',
          },
        }}>
        <Menu.Target>
          <Group gap={8}>
            {selectedId !== null && showColorBox && (
              <Box
                bg={getColor()}
                w={8}
                h={16}
              />
            )}
            {largeScreen ? (
              <Group gap={'xs'}>
                <Text size={'sm'} color={selectedId === null ? 'dimmed' : theme.black}>
                  {getText()}
                </Text>
                <IconChevronDown size={14} color={theme.colors.dark[3]} />
              </Group>
            ) : (
              <IconFilter color={theme.colors[theme.primaryColor][7]} />
            )}
          </Group>
        </Menu.Target>
        <Menu.Dropdown>
          <ScrollArea.Autosize mah={350} mx={'auto'}>
            {emptyText && (
              <Menu.Item
                key={'menu_item_all'}
                onClick={() => {
                  onChange(null);
                }}>
                <Group gap={'xs'}>
                  {showColorBox && (
                    <Box
                      bg={theme.white}
                      w={8}
                      h={16}
                    />
                  )}
                  <Text size={'sm'}>{emptyText}</Text>
                </Group>
              </Menu.Item>
            )}
            {menuItems?.map((p) => (
              <Menu.Item
                key={`menu_item_${p.id}`}
                onClick={() => {
                  onChange(p.id);
                }}>
                <Group gap={'xs'}>
                  {showColorBox && (
                    <Box
                      bg={p.color}
                      w={8}
                      h={16}
                    />
                  )}
                  <Text fz={'sm'}>{p.text}</Text>
                </Group>
              </Menu.Item>
            ))}
          </ScrollArea.Autosize>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};
