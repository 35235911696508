import { TextInput } from '@mantine/core';
import { FC } from 'react';
import { INPUT_MAX_LENGTH } from '../../../../App';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { LiitNumberInput } from '../../../LiitNumberInput/LiitNumberInput';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { FormLabel } from '../Common/FormLabel';
import { TextBoxInputType } from './TextBoxInputType';
import { ViewerTypedTextBox } from './ViewerTypedTextBox';

type TypedTextBoxProps = {
  element: FormElement & FormElementTypedTextBox;
} & FormElementComponentProps;

export const TypedTextBox: FC<TypedTextBoxProps> = ({ number, element, mode, error, onChange }) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerTypedTextBox number={number} element={element} />;
  }

  const { value, description, label, required, hideLabel, labelAndTextPosition } = element;

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);
  const text = (value as string) ?? '';
  const { inputType } = element;

  if (inputType === TextBoxInputType.RequestedAmount || inputType === TextBoxInputType.Number) {
    return (
      <LiitNumberInput
        styles={{
          input: {
            textAlign: labelAndTextPosition ?? 'left',
          },
        }}
        hideControls
        disabled={mode === FormElementMode.Editor}
        description={descriptionText}
        label={
          hideLabel ? undefined : <FormLabel element={element} mode={mode} justify={'space-between'} />
        }
        placeholder={hideLabel === undefined ? undefined : labelText}
        required={required}
        withAsterisk={false}
        value={value ? Number(value) : undefined}
        maxLength={50}
        error={error}
        onChange={(event) => {
          if (onChange) {
            onChange(event?.toString() ?? null);
          }
        }}
      />
    );
  }

  return (
    <TextInput
    styles={{
      input: {
        textAlign: labelAndTextPosition ?? 'left',
      },
    }}
      disabled={mode === FormElementMode.Editor}
      description={descriptionText}
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} justify={'space-between'} />}
      placeholder={hideLabel === undefined ? undefined : labelText}
      required={required}
      value={text}
      error={error}
      withAsterisk={false}
      maxLength={INPUT_MAX_LENGTH}
      onChange={(event) => {
        if (onChange) {
          onChange(event.currentTarget.value ?? '');
        }
      }}
    />
  );
};
