import { Flex, Grid, Switch, TextInput } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { ContactPersonValue } from './ContactPersonValue';
import { ViewerContactPerson } from './ViewerContactPerson';

type ContactPersonProps = {
  element: FormElement;
} & FormElementComponentProps;

export const ContactPerson: React.FC<ContactPersonProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerContactPerson number={number} element={element} />;
  }

  const value = element.value as ContactPersonValue;

  const { isByProxy, contactPersonFirstName, contactPersonLastName } = value;

  return (
    <Flex direction={'column'}>
      <Switch
        label={t`Jag är ombud för en annan person`}
        checked={value.isByProxy}
        onClick={() => {
          if (onChange) {
            onChange({
              isByProxy: !isByProxy,
              contactPersonFirstName: '',
              contactPersonLastName: '',
            });
          }
        }}
      />
      {(isByProxy || mode === FormElementMode.Editor) && (
        <Grid gutter={'lg'} pt={16}>
          <Grid.Col span={{ lg: 6 }}>
            <TextInput
              disabled={mode === FormElementMode.Editor}
              label={t`Förnamn ombud`}
              required={true}
              value={contactPersonFirstName}
              error={error}
              onChange={(event) => {
                if (onChange) {
                  onChange({ ...value, contactPersonFirstName: event.currentTarget.value ?? '' });
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: 6 }}>
            <TextInput
              disabled={mode === FormElementMode.Editor}
              label={t`Efternamn ombud`}
              required={true}
              value={contactPersonLastName}
              error={!!error}
              onChange={(event) => {
                if (onChange) {
                  onChange({ ...value, contactPersonLastName: event.currentTarget.value ?? '' });
                }
              }}
            />
          </Grid.Col>
        </Grid>
      )}
    </Flex>
  );
};
