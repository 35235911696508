import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Title, Group, Loader, Stack, Text, Flex, Paper } from '@mantine/core';
import { IconCalendar, IconReportSearch, IconUser } from '@tabler/icons-react';
import { Application } from '../../../models/Application';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { FormViewer } from '../../../components/formbuilder/FormViewer';
import { isMissing, isPresent } from 'utilitype';
import { ViewNavigation } from './ViewNavigation';
import { applicationViewStyles } from './applicationViewStyles';
import { ReviewInformation } from '../../common/application/ReviewInformation';
import Formatter from '../../../utils/formatter';
import { ApplicantTypes } from '../../period/ApplicationPeriodView';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { ApplicationToolBar } from './ApplicationToolbar';
import { ApplicationAttachments } from './ApplicationAttachments';
import { useSessionStorage } from '@mantine/hooks';
import { SessionStorageKeys } from '../../../components/LiitDrawer/LiitDrawer';
import { AttachmentListItem } from '../../../models/AttachmentListItem';
import { LiitAttachmentDrawer } from '../../../components/LiitAttachmentDrawer/LiitAttachmentDrawer';

export const QueryKeys = {
  AllImages: 'all-images',
};
import { ApplicationTags } from './ApplicationTags';
import { Tag } from '../../../models/Tag';
import { useApplicationQueryParameters } from '../../../hooks/useApplicationQueryParameters';
import { ApplicationQueryType } from '../../../models/ApplicationItem';

export const ApplicationView: React.FC = () => {
  const { selectedOrganisation } = useSession();
  const { classes, theme } = applicationViewStyles();
  const { applicationId } = useParams();
  const api = useApi();
  const [application, setApplication] = useState<Application>();

  const [, setApplicationAttachments] = useSessionStorage<AttachmentListItem[]>({
    key: SessionStorageKeys.ApplicationAttachments,
  });
  const [tags, setTags] = useState<Tag[]>();
  const [parameters] = useApplicationQueryParameters();

  const fetchApplication = async () => {
    if (selectedOrganisation && applicationId) {
      const applicationResponse = await api.getApplication(selectedOrganisation.id, applicationId);

      setApplication(applicationResponse);
      setApplicationAttachments(
        applicationResponse && isPresent(applicationResponse?.attachments)
          ? applicationResponse.attachments
          : [],
      );

      const tagsResponse = await api.getTags(selectedOrganisation.id);
      setTags(tagsResponse);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchApplication();
    };

    fetchData();
  }, [selectedOrganisation, applicationId]);

  if (isMissing(selectedOrganisation) || isMissing(application)) {
    return (
      <Group justify={'center'} align={'top'} pt={40} style={{ flex: 1 }}>
        <Loader size={'xl'} />
      </Group>
    );
  }

  const removeTagFromApplication = async (tag: Tag) => {
    await api.removeTagFromApplication(selectedOrganisation!.id, application.id, tag.id);

    setApplication((prevState) => {
      if (prevState) {
        const index = prevState.tags.indexOf(tag);
        const newTags = [...prevState.tags];
        newTags.splice(index, 1);
        return { ...prevState, tags: newTags };
      }
    });
  };

  const addTagToApplication = async (tag: Tag) => {
    await api.addTagToApplication(selectedOrganisation!.id, application.id, tag.id);

    setApplication((prevState) => {
      if (prevState) {
        const newTags = [...prevState.tags];
        newTags.push(tag);
        return { ...prevState, tags: newTags };
      }
    });
  };

  return (
    <>
      <GrantMeContainer scrollable={false}>
        <GrantMeTitleContainer>
          <Group
            justify={'space-between'}
            wrap={'nowrap'}
            style={{ flex: '1 1 auto', minWidth: 0, overflow: 'hidden' }}>
            <Stack gap={'xs'} style={{ overflow: 'hidden' }}>
              <Title
                order={3}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>{`${application.number} ${application.title}`}</Title>
              <Group>
                <Group gap={4} wrap={'nowrap'}>
                  <IconCalendar size={18} color={theme.colors.gray[6]} />
                  <Text fw={600} size={'sm'} color={'dimmed'} style={{ whiteSpace: 'nowrap' }}>
                    {Formatter.formatDateString(application.submittedUtc)}
                  </Text>
                </Group>
                <Group gap={4} wrap={'nowrap'}>
                  <IconUser size={18} color={theme.colors.gray[6]} />
                  <Text fw={600} size={'sm'} color={'dimmed'} style={{ whiteSpace: 'nowrap' }}>
                    {ApplicantTypes[application.applicantType]}
                  </Text>
                </Group>
                <Group gap={4} wrap={'nowrap'} style={{ minWidth: 0, flex: 1 }}>
                  <IconReportSearch size={18} color={theme.colors.gray[6]} />
                  <Text
                    fw={600}
                    size={'sm'}
                    color={'dimmed'}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {application.periodTitle}
                  </Text>
                </Group>
              </Group>
            </Stack>
            <Stack>
              <ViewNavigation application={application} tags={tags} addTag={addTagToApplication} />
              <ApplicationTags application={application} removeTag={removeTagFromApplication} />
            </Stack>
          </Group>
        </GrantMeTitleContainer>

        {(parameters?.type === ApplicationQueryType.ToReview ||
          parameters?.type === ApplicationQueryType.Reviewed) && (
          <ReviewInformation
            filterOnId={application.periodId}
            triggerOnChange={application.reviewStatus}
          />
        )}
        <Flex direction={'row'} style={{ flex: 1, overflow: 'hidden' }}>
          <div className={classes.aAndA}>
            <div className={classes.scrollContainer}>
              <Paper className={classes.formContainer} w={'100%'}>
                <FormViewer json={JSON.stringify(application.applicationJson)} showPageLabels />
              </Paper>
            </div>
            {isPresent(application.attachments) && (
              <div className={classes.attachmentsContainer}>
                <ApplicationAttachments attachments={application.attachments} />
              </div>
            )}
          </div>

          {application && (
            <ApplicationToolBar
              application={application}
              onChanged={async () => {
                await fetchApplication();
              }}
            />
          )}
        </Flex>
      </GrantMeContainer>
      <LiitAttachmentDrawer attachmentsList={application?.attachments ?? []} />
    </>
  );
};
