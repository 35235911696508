import { IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';

const columnStyles: React.CSSProperties = {
  //width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ApplicationPeriodListColumns1 = (): Array<IDisplayColumn> => [
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { cursor: 'pointer', userSelect: 'none', paddingLeft: 40  },
  },
  {
    displayName: 'Startdatum',
    propertyName: 'startDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles },
  },
  {
    displayName: 'Slutdatum',
    propertyName: 'endDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles },
  },
  {
    displayName: 'Budget',
    propertyName: 'budget',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles },
  },
];

export const ApplicationPeriodListColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { whiteSpace: 'nowrap', cursor: 'pointer', userSelect: 'none', paddingLeft: 40 },
  },
  {
    displayName: 'Startdatum',
    propertyName: 'startDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles },
  },
  {
    displayName: 'Slutdatum',
    propertyName: 'endDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles },
  },
  {
    displayName: 'Publik',
    propertyName: 'isPublished',
    propertyType: PropertyType.Boolean,
    columnStyles: { ...columnStyles },
  },
  {
    displayName: 'Budget',
    propertyName: 'budget',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles },
  },
];
