import { Group } from '@mantine/core';
import { ReactNode } from 'react';
import { t } from 'ttag';
import { RenderCell, CellRenderInformation } from '../../components/LiitGrid/RenderCell';

export const RenderWeightCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { rowValues, theme } = rowInformation;
  const { weight } = rowValues;

  return (
    <Group gap={'xs'} justify={'start'} align={'center'}>
      { weight === 100 ? t`Ja` : t`Nej`}
    </Group>
  );
};
