import React from 'react';
import { Card, Flex, Group, RingProgress, Text, Divider, Box } from '@mantine/core';
import Formatter from '../../../utils/formatter';
import { PeriodApprovalSummary } from './PeriodApprovalSummary';
import { t } from 'ttag';
import { createStyles } from '@mantine/emotion';
import { BORDER_COLOR } from '../../../theme';

const useStyles = createStyles((theme) => ({
  container: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
  valueContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
}));

interface ApprovalInformationProps {
  period: PeriodApprovalSummary;
}

export const ApprovalInformation: React.FC<ApprovalInformationProps> = ({ period }) => {
  const { classes, theme } = useStyles();

  const getProgressRingSections = () => {
    const total =
      period.numberOfApprovedApplications +
      period.numberOfPreliminaryApprovedApplications +
      period.numberOfDeclinedApplications +
      period.numberOfPreliminaryDeclinedApplications +
      period.numberOfSubmittedApplications;

    const approved =
      ((period.numberOfApprovedApplications + period.numberOfPreliminaryApprovedApplications) /
        total) *
      100;
    const submitted = (period.numberOfSubmittedApplications / total) * 100;
    const declined =
      ((period.numberOfDeclinedApplications + period.numberOfPreliminaryDeclinedApplications) /
        total) *
      100;

    return [
      { value: isNaN(approved) ? 0 : Math.round(approved), color: theme.colors.green[5] },
      { value: isNaN(submitted) ? 0 : Math.round(submitted), color: theme.colors.gray[6] },
      { value: isNaN(declined) ? 0 : Math.round(declined), color: theme.colors.red[3] },
    ];
  };

  const getApplicationsCompleted = () => {
    const total =
      period.numberOfApprovedApplications +
      period.numberOfPreliminaryApprovedApplications +
      period.numberOfDeclinedApplications +
      period.numberOfPreliminaryDeclinedApplications +
      period.numberOfSubmittedApplications;

    const percentage = Math.round(100 - (period.numberOfSubmittedApplications / total) * 100);

    return isNaN(percentage) ? 0 : percentage;
  };

  return (
    <Box className={classes.container} key={period.id + '1'} p={8} pr={32}>
      <Flex direction={'row'} align={'center'}>
        <Flex className={classes.valueContainer}>
          <Text color="dimmed" tt="uppercase" fw={700} size="xs">
            {t`Sökt belopp`}
          </Text>
          <Text fw={700} size="lg">
            {Formatter.formatCurrency(period.requestedAmount)}
          </Text>
        </Flex>
        <Divider color={BORDER_COLOR} orientation={'vertical'} />
        <Flex className={classes.valueContainer}>
          <Text color="dimmed" tt="uppercase" fw={700} size="xs">
            {t`Budget`}
          </Text>
          <Text fw={700} size="lg">
            {Formatter.formatCurrency(period.budget)}
          </Text>
        </Flex>
        <Divider color={BORDER_COLOR} orientation={'vertical'} />
        <Flex className={classes.valueContainer} align={'left'} justify={'flex-end'}>
          <Text color="dimmed" tt="uppercase" fw={700} size="xs">
            {t`Beviljat belopp`}
          </Text>
          <Text
            fw={700}
            size="lg"
            color={
              period.budget && period.preliminaryGrantedAmount > period.budget
                ? theme.colors.red[3]
                : theme.black
            }>
            {Formatter.formatCurrency(period.preliminaryGrantedAmount)}
          </Text>
          {period.budget && period.preliminaryGrantedAmount > period.budget && (
            <Text size="xs" color={theme.colors.red[3]}>
              {Formatter.formatCurrency(period.preliminaryGrantedAmount - period.budget)} över
              budget
            </Text>
          )}
        </Flex>
        <Divider color={BORDER_COLOR} orientation={'vertical'} />
        <Flex className={classes.valueContainer} direction={'row'} gap={0}>
          <div style={{ flex: 1 }}>
            <Group justify={'space-between'} w={'100%'} pl={'xl'} pr={'xl'} gap={0}>
              <Text size={'xs'} fw={700} color={'dimmed'} tt={'uppercase'}>
                {t`Ta beslut om`}
              </Text>
              <Text size={'xs'} fw={700} color={theme.colors.gray[6]}>
                {period.numberOfSubmittedApplications} st
              </Text>
            </Group>
            <Group justify={'space-between'} w={'100%'} pl={'xl'} pr={'xl'} gap={0}>
              <Text size={'xs'} fw={700} color={'dimmed'} tt={'uppercase'}>
                {t`Beviljade`}
              </Text>
              <Text size={'xs'} fw={700} color={theme.colors.green[5]}>
                {period.numberOfPreliminaryApprovedApplications +
                  period.numberOfApprovedApplications}{' '}
                st
              </Text>
            </Group>
            <Group justify={'space-between'} w={'100%'} pl={'xl'} pr={'xl'} gap={0}>
              <Text size={'xs'} fw={700} color={'dimmed'} tt={'uppercase'}>
                {t`Nekade`}
              </Text>
              <Text size={'xs'} fw={700} color={theme.colors.red[3]}>
                {period.numberOfPreliminaryDeclinedApplications +
                  period.numberOfDeclinedApplications}{' '}
                st
              </Text>
            </Group>
          </div>
          <RingProgress
            sections={getProgressRingSections()}
            label={
              <Text fw={700} ta="center" size="md">
                {getApplicationsCompleted()}%
              </Text>
            }
            size={100}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
