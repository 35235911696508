import { Stack, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { PaymentBatchViewColumns } from './PaymentBatchViewColumns';
import { useNavigate, useParams } from 'react-router-dom';
import Formatter from '../../utils/formatter';

export const PaymentBatchView: React.FC = () => {
  const [paymentBatch, setPaymentBatch] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const { paymentBatchId } = useParams();
  const navigate = useNavigate();

  const refreshPaymentsBatch = async () => {
    const result = await api.getPaymentBatch(selectedOrganisation!.id, paymentBatchId!);
    setPaymentBatch(result);
  };

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refreshPaymentsBatch();

      setIsFetching(false);
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation]);

  return (
    <>
      <GrantMeContainer>
        {paymentBatch &&
          <Stack gap={0}>

            <GrantMeTitleContainer>
              <Title order={3}>Betalade {Formatter.formatDateString(paymentBatch.createdUtc)}</Title>
            </GrantMeTitleContainer>


            <LiitGrid
              data={paymentBatch.payments}
              columnInfo={PaymentBatchViewColumns()}
              highligthOnHoover
              fetchingData={isFetching}
              onRowClick={(id: string) => {
                const payment = paymentBatch.payments.find((x) => x.id === id);
                navigate(`/paymentbatches/${paymentBatch.id}/${payment.applicationId}`);
              }}
            />

          </Stack>
        }
      </GrantMeContainer>
    </>
  );
};
