import { MantineThemeOverride, Table } from '@mantine/core';
import { customColors } from './utils/customColors';

export const BORDER_COLOR = '#cbd0dd';
const fontFamilyInter = `${"'Inter Tight', sans-serif"}`;

export const customTheme: MantineThemeOverride = {
  colors: customColors,
  primaryColor: 'blue',
  primaryShade: { light: 5, dark: 4 },
  defaultRadius: 'sm',
  defaultGradient: {
    from: 'orange',
    to: 'red',
    deg: 45,
  },
  fontFamily: fontFamilyInter,
  headings: { fontFamily: `${"'Exo 2', sans-serif"}` },
  components: {
    Accordion: {
      styles: (theme) => ({
        label: {
          fontSize: theme.fontSizes.md,
          color: theme.colors.gray[9],
          fontWeight: 600,
        },
      }),
    },
    Tabs: {
      styles: (theme) => ({
        tab: {
          color: theme.colors[theme.primaryColor][7],
          fontWeight: 600,
          '&[dataActive]': {
            color: theme.colors[theme.primaryColor][7],
          },
        },
        tabsList: {
          border: '0px',
        },
      }),
    },
    TextInput: {
      styles: (theme) => ({
        label: {
          color: theme.colors[theme.primaryColor][7],
          fontWeight: 'bold',
          display: 'inline-flex',
        },
      }),
    },
    InputWrapper: {
      styles: (theme) => ({
        label: {
          color: theme.colors[theme.primaryColor][7],
          fontWeight: 'bold',
          display: 'inline-flex',
          gap: '8px',
          width: '100%',
        },
      }),
    },
    Table: Table.extend({
      vars: (theme) => {
        return {
          table: {},
          th: {
            background: theme.colors.gray[1],
            color: theme.colors.gray[9],
          },
          td: {
            '&:hover': {
              backgroundColor: 'red',
              borderBottom: `1px solid ${BORDER_COLOR}`,
            },
          },
        };
      },
    }),

    Divider: {
      styles: (theme) => ({
        root: {
          borderTopColor: `${theme.colors.gray[3]} !important`,
          borderLeftColor: `${theme.colors.gray[3]} !important`,
        },
      }),
    },

    Paper: {
      styles: (theme) => ({
        root: {
          ['@media print']: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      }),
    },

    Button: {
      styles: {
        label: {
          textTransform: 'uppercase',
        },
        root: {
          ['@media print']: {
            display: 'none',
          },
        },
      },
    },

    ActionIcon: {
      styles: {
        root: {
          ['@media print']: {
            display: 'none',
          },
        },
      },
    },

    Title: {
      styles: (theme) => ({
        root: {
          color: theme.colors.gray[9],
          //textTransform: 'uppercase',
          fontFamily: fontFamilyInter,
          ['@media print']: {
            color: theme.black,
            fontFamily: fontFamilyInter,
          },
        },
      }),
    },

    Text: {
      styles: (theme) => ({
        root: {
          ['@media print']: {
            color: theme.black,
            fontFamily: fontFamilyInter,
          },
        },
      }),
    },
  },
};