import { Group, useMantineTheme } from '@mantine/core';
import React from 'react';
import { CONTAINER_PADDING_WIDTH } from './Layout';
import { BORDER_COLOR } from '../theme';

interface GrantMeTitleContainerProps {
  children: React.ReactNode;
  backgroundColor?: string | undefined;
  position?: string | undefined;
  compact?: boolean | undefined;
}

export const GrantMeTitleContainer: React.FC<GrantMeTitleContainerProps> = ({
  children,
  backgroundColor,
  position,
  compact,
}) => {
  const theme = useMantineTheme();

  return (
    <Group
      mih={compact ? 0 : 94}
      justify={position ? position : 'space-between'}
      pl={CONTAINER_PADDING_WIDTH}
      pr={CONTAINER_PADDING_WIDTH}
      wrap={'nowrap'}
      align={'flex-start'}
      w={'100%'}
      pt={compact ? 'sm' : 'lg'}
      pb={compact ? 'sm' : 'lg'}
      style={{
        alignItems: 'center',
        backgroundColor: backgroundColor ? backgroundColor : theme.white,
        borderBottom: `1px solid ${BORDER_COLOR}`,
      }}>
      {children}
    </Group>
  );
};
