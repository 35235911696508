import React, { ReactNode, useEffect, useState } from 'react';
import { isMissing, isPresent } from 'utilitype';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { LiitNumberInput } from '../../../components/LiitNumberInput/LiitNumberInput';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import formatter from '../../../utils/formatter';

interface RenderAmountTextBoxCellProps {
  rowInformation: CellRenderInformation;
}

export const RenderAmountTextBoxCell: React.FC<RenderAmountTextBoxCellProps> = ({
  rowInformation,
}) => {
  const { cellValue, rowValues } = rowInformation;
  const [amount, setAmount] = useState(cellValue);
  const { selectedOrganisation } = useSession();
  const api = useApi();

  useEffect(() => {
    if (amount !== cellValue) {
      setAmount(cellValue);
    }
  }, [cellValue]);

  const updateDecision = async (newValue: string) => {
    if (!selectedOrganisation) {
      return null;
    }

    const value = !isPresent(newValue) ? undefined : Number(newValue);
    const cell = !isPresent(cellValue) ? undefined : cellValue;
    const sameValue = value === cell;

    if (sameValue || (isPresent(value) && isNaN(value))) {
      return null;
    }

    const decision = {
      decision: isMissing(value)
        ? ApplicationStatus.Submitted
        : value > 0
          ? ApplicationStatus.PreliminaryApproved
          : ApplicationStatus.PreliminaryDeclined,
      grantedAmount: amount,
    };

    const response = await api.updateApplicationDecision(
      selectedOrganisation.id,
      rowValues.id,
      decision,
    );

    const newStatus = (await response.json()).status;

    rowInformation.setData((prev) => {
      const app = prev.find((x) => x.id === rowValues.id);
      app.status = newStatus;
      app.grantedAmount = value;
      const newPrev = [...prev];
      return newPrev;
    });

    if (rowInformation.onCellValueChanged) {
      rowInformation.onCellValueChanged();
    }
  };

  if (
    rowValues.status === ApplicationStatus.Approved ||
    rowValues.status === ApplicationStatus.Declined
  ) {
    return <>{formatter.formatCurrency(amount)}</>;
  } else {
    return (
      <LiitNumberInput
        styles={{
          input: {
            textAlign: 'right',
          },
        }}
        hideControls
        value={isMissing(amount) ? '' : amount}
        onChange={(value) => setAmount(isPresent(value) ? Number(value) : undefined)}
        onBlur={async (event) => updateDecision(event.target.value?.replace(/\s+/g, ''))}
      />
    );
  }
};
