import { useMsal } from '@azure/msal-react';
import {
  Menu,
  Group,
  Stack,
  Space,
  Text,
  useMantineTheme,
  Avatar,
} from '@mantine/core';
import { IconSettings, IconUser, IconLogout, IconChevronDown } from '@tabler/icons-react';
import React from 'react';
import { t } from 'ttag';
import { useSession } from '../hooks/useSession';
import { useNavigate } from 'react-router-dom';
import { createStyles } from '@mantine/emotion';

const useStyles = createStyles((theme) => ({
  menuItemWrapper: {
    //backgroundColor: theme.colors.blue[8],
    padding: '8px 8px',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][7],
    },
  },

  truncate: {
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: '140px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const COLORS = ['indigo.3', 'red.3', 'orange.3', 'green.3', 'grape.3'];
const COLORS_LENGTH = COLORS.length;

export const OrganisationAndProfileMenu: React.FC = () => {
  const theme = useMantineTheme();
  const { selectedOrganisation, organisations, selectOrganisation, user } = useSession();

  const { instance } = useMsal();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const selectedIndex = organisations?.findIndex((x) => x.id === selectedOrganisation?.id) ?? 0;

  return (
    <Menu
      shadow={'lg'}
      transitionProps={{ transition: 'pop' }}
      position={'bottom-end'}
      styles={{
        item: {
          padding: '6px 12px',
        },
      }}>
      <Menu.Target>
        <div className={classes.menuItemWrapper}>
          <Group gap={8} wrap={'nowrap'}>
            <Stack p={0} gap={0}>
              <Text
                size={'sm'}
                fw={'bold'}
                className={classes.truncate}
                c={theme.colors.gray[0]}>
                {selectedOrganisation?.name}
              </Text>
            </Stack>
            {selectedOrganisation && (
              <Avatar ml={4} variant={'filled'} radius={4} size={'sm'} color={COLORS[selectedIndex % COLORS_LENGTH]}>
                {selectedOrganisation?.name[0]}
              </Avatar>
            )}
            <IconChevronDown size={18} color={theme.colors.gray[0]} />
          </Group>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{t`Organisationer`}</Menu.Label>
        {organisations?.map((f, index) => (
          <Menu.Item
            key={`menu_item_${f.id}`}
            onClick={async () => {
              const organisation = organisations.find((x) => x.id === f.id);
              if (organisation && selectOrganisation) {
                await selectOrganisation(organisation);
                navigate('/');
              }
            }}>
            <Group gap={'xs'} pr={8}>
              <Avatar size={'sm'} radius={4} variant={'filled'} color={COLORS[index % COLORS_LENGTH]}>
                {f.name[0]}
              </Avatar>
              <Text size={'sm'}>{f.name}</Text>
            </Group>
          </Menu.Item>
        ))}
        <Space h={8} />
        <Menu.Label>{user?.firstName} {user?.lastName}</Menu.Label>
              <Menu.Item onClick={() => navigate('/user/profile')} leftSection={<IconUser size={14} />}>
                  <Text size={'sm'}>Inställningar</Text>
        </Menu.Item>
              <Menu.Item onClick={() => navigate('/organisations')} leftSection={<IconSettings size={14} />}>
                  <Text size={'sm'}>Mina organisationer</Text>
        </Menu.Item>
        <Menu.Item leftSection={<IconLogout size={14} />} onClick={() => instance.logoutRedirect()}>
          <Text size={'sm'}>{t`Logga ut`}</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
