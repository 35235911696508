import { Button, Flex, Space } from '@mantine/core';
import { FC } from 'react';
import { LiitFormContainerStyles } from './LiitFormContainerStyles';

export const LiitFormContainerFooter: FC<{ confirmText: string; onCancel: () => void }> = ({
  confirmText,
  onCancel,
}) => {
  const { classes } = LiitFormContainerStyles();

  return (
    <Flex justify={'end'} align={'center'} pt={24} className={classes.footer}>
      <Button variant="outline" onClick={onCancel}>
        Avbryt
      </Button>
      <Space w="md" />
      <Button type="submit">{confirmText}</Button>
    </Flex>
  );
};
