import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { Group, Button } from '@mantine/core';
import { t } from 'ttag';
import { useApi } from '../../../hooks/useApi';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { useSession } from '../../../hooks/useSession';

export const RenderDecisionButtonsCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { cellValue, rowValues, theme } = rowInformation;
  const { selectedOrganisation } = useSession();
  const api = useApi();

  const currentStatus = cellValue as ApplicationStatus;

  const updateStatus = async (changeStatus: ApplicationStatus) => {
    if (!selectedOrganisation) {
      return;
    }

    const response = await api.updateApplicationDecision(selectedOrganisation.id, rowValues.id, {
      decision: changeStatus,
      grantedAmount: null,
    });

    const newStatus = (await response.json()).status;

    rowInformation.setData((prev) => {
      const app = prev.find((x) => x.id === rowValues.id);
      app.status = newStatus;
      const newPrev = [...prev];
      return newPrev;
    });

    if (rowInformation.onCellValueChanged) {
      rowInformation.onCellValueChanged();
    }
  };

  if (
    currentStatus === ApplicationStatus.PreliminaryApproved ||
    currentStatus === ApplicationStatus.PreliminaryDeclined
  ) {
    return (
      <Button
        size={'xs'}
        variant={'outline'}
        onClick={() => updateStatus(ApplicationStatus.Submitted)}>{t`Ångra beslut`}</Button>
    );
  }

  return (
    <Group wrap={'nowrap'} gap={'xs'}>
      <Button
        w={76}
        size={'xs'}
        color={'red.4'}
        onClick={() => updateStatus(ApplicationStatus.PreliminaryDeclined)}>{t`Neka`}</Button>
      <Button
        w={76}
        size={'xs'}
        color={'green.5'}
        onClick={() => updateStatus(ApplicationStatus.PreliminaryApproved)}>{t`Bevilja`}</Button>
    </Group>
  );
};
