import React from 'react';
import { Group, Text, Center, Table } from '@mantine/core';
import { styles } from './Styles';
import { TableHeaderProperties } from './TableHeaderProperties';
import { getChevron } from './GetChevron';
import { PropertyType } from './PropertyType';

export const TableHeader: React.FC<TableHeaderProperties> = ({
  children,
  reversed,
  sorted,
  onSort,
  disableSort = false,
  columnStyles = {},
  propertyType,
  column,
}) => {
  const { classes, cx } = styles();

  return (
    <Table.Th
      className={cx(classes.th, classes.paddingOnFirstChild, classes.paddingOnLastChild)}
      onClick={disableSort ? undefined : onSort}
      style={columnStyles}>
      <Group
        justify={propertyType === PropertyType.Currency ? 'end' : 'space-between'}
        gap="xs"
        wrap={'nowrap'}
        style={{ gap: 0 }}>
        {!disableSort && propertyType === PropertyType.Currency && (
          <Center inline className={classes.icon}>
            {getChevron(sorted, reversed)}
          </Center>
        )}

        <Text fw={700} size="sm">
          {children}
        </Text>

        {!disableSort && propertyType !== PropertyType.Currency && (
          <Center inline className={classes.icon}>
            {getChevron(sorted, reversed)}
          </Center>
        )}
      </Group>
    </Table.Th>
  );
};
