import { ActionIcon, Group, Table, Text, Popover, Indicator } from '@mantine/core';
import { IconInfoCircle, IconReport } from '@tabler/icons-react';
import React from 'react';
import { Dashboard } from '../../../models/Dashboard';
import { DashboardCard } from './DashboardCard';
import Formatter from '../../../utils/formatter';
import { dashboardViewStyles } from '../DashboardViewStyles';
import { useDisclosure } from '@mantine/hooks';
import { ApplicationPeriodStatus } from '../../../models/ApplicationPeriodStatus';
import { HoverTooltip } from '../../../components/HoverTooltip';
import App from '../../../App';
import { t } from 'ttag';

interface ApplicationPeriodsCardProps {
  data: Dashboard;
}

export const ApplicationPeriodsCard: React.FC<ApplicationPeriodsCardProps> = ({ data }) => {
  const { classes } = dashboardViewStyles();
  const [opened, { close, open }] = useDisclosure(false);

  const getApplicationPeriods = () => {
    return data.applicationPeriods.filter(
      (x) =>
        x.status === ApplicationPeriodStatus.Ongoing ||
        x.status === ApplicationPeriodStatus.Upcoming,
    );
  };

  const getContent = () => {
    if (getApplicationPeriods().length === 0) {
      return (
        <Text color="dimmed">
          Just nu finns det inga pågående eller kommande ansökningsperioder.
        </Text>
      );
    }

    return (
      <Table verticalSpacing={'xs'} className={classes.dashboardTable}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t`Period`}</Table.Th>
            <Table.Th>{t`Status`}</Table.Th>
            <Table.Th>{t`Slutdatum`}</Table.Th>
            <Table.Th>{t`Inlämnade`}</Table.Th>
            <Table.Th>
              <HoverTooltip content="Avser antalet ansökningar som är påbörjade av sökanden men ännu ej inlämnade.">
                <Group>
                  Påbörjade{' '}
                  <ActionIcon
                    color="blue.6"
                    variant="transparent"
                    size={22}
                    onMouseEnter={open}
                    onMouseLeave={close}>
                    <IconInfoCircle />
                  </ActionIcon>
                </Group>
              </HoverTooltip>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {getApplicationPeriods().map((period) => {
            return (
              <Table.Tr key={'ongoingperiodscard_' + period.id}>
                <Table.Td>
                  <Text fz={14} fw={500}>
                    {period.title}
                  </Text>
                </Table.Td>

                <Table.Td>
                  {period.status === ApplicationPeriodStatus.Ongoing && (
                    <Indicator offset={-12} color={'green'} position="middle-start">
                      <Text fz={14} c="gray.7">{t`Pågående`}</Text>
                    </Indicator>
                  )}
                  {period.status === ApplicationPeriodStatus.Upcoming && (
                    <Indicator offset={-12} color={'gray'} position="middle-start">
                      <Text fz={14} c="gray.7">{t`Kommande`}</Text>
                    </Indicator>
                  )}
                </Table.Td>
                <Table.Td>
                  <Text fz={14}>{Formatter.formatDateString(period.endDate)}</Text>
                </Table.Td>
                <Table.Td>
                  <Text fz={14}>
                    {period.numberOfSubmittedApplications +
                      period.numberOfApprovedApplications +
                      period.numberOfDeclinedApplications}{' '}
                    {t`st`}
                  </Text>
                </Table.Td>
                <Table.Td>
                  {period.numberOfDraftApplications} {t`st`}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <DashboardCard
      title={t`Aktuella ansökningsperioder`}
      icon={IconReport}
      content={getContent()}
    />
  );
};
