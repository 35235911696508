import { TextInput } from '@mantine/core';
import { IconMathFunctionY } from '@tabler/icons-react';
import React, { BaseSyntheticEvent } from 'react';
import { t } from 'ttag';
import { WayOfPayment } from '../../../../models/WayOfPayment';
import { dashSpaceAndNumber, dashSpaceAndNumberCleanUp } from '../../../../utils/textFormatters';

interface InputBgPgProps {
  wayOfPayment: WayOfPayment;
  value: string;
  error: boolean;
  onChange: (newValue) => void;
}

export const InputBgPg: React.FC<InputBgPgProps> = ({ wayOfPayment, value, error, onChange }) => {

  const label = wayOfPayment === WayOfPayment.BankGiro ? t`BankGiro` : t`PlusGiro`; 

  return (
    <TextInput
      label={label}
      type={'text'}
      required
      maxLength={16}
      value={value}
      error={error}
      onInput={(event: BaseSyntheticEvent<InputEvent>) => {
        const { value: newValue } = event.target;
        const formated = dashSpaceAndNumber(newValue);
        event.target.value = formated;
      }}
      onChange={(event) => {
        const { value: newValue } = event.target;
        onChange(newValue);
      }}
      onBlur={(event) => {
        const { value: newValue } = event.target;
        onChange(dashSpaceAndNumberCleanUp(newValue));
      }}
    />
  );
};