import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  pdfWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  navigationButtonWrapper: {

  },
  navigationButton: {
    position: 'absolute',
    zIndex: 500000,
    backgroundColor: theme.colors.gray[1],
    border: `1px solid ${theme.colors.blue[6]}`,
    bottom: '50%',
    borderRadius: '100%',
    borderWidth: 2,
    '&.left': {
      left: 48,
    },
    '&.right': {
      right: 48,
    },
  },

  controlls: {
    position: 'absolute',
    zIndex: 500000,
    width: '100%',
    bottom: 54,
    right: 0,
  },
  controllsDownload: {
    position: 'absolute',
    zIndex: 500000,
    top: 32,
    right: 32,
    display: 'flex',
    padding: 8,
    borderRadius: 8,
    backgroundColor: theme.colors.gray[2],
    border: `1px solid ${theme.colors.blue[6]}`,
    justifyContent: 'center',
    alignItems: 'center',
  },
  controllsClose: {
    position: 'absolute',
    zIndex: 500000,
    top: 16,
    left: 16,
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  showButtons: {
    opacity: 1,
  },
  controllPanel: {
    display: 'flex',
    backgroundColor: theme.colors.gray[2],
    border: `1px solid ${theme.colors.blue[6]}`,
    padding: 8,
    borderRadius: 8,
  },

}));