import { createStyles } from '@mantine/emotion';

export const editorStyles = createStyles((theme) => ({
  wrapper: {
    border: '1px solid lightgray',
    padding: 6,
    borderRadius: 4,
    position: 'relative',
  },
  editorButtons: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 5,
    alignItems: 'center',
  },
  elementTypeName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%)',
    textAlign: 'center',
    border: '1px solid #e9ecef',
    borderTop: 'none',
    backgroundColor: '#f1f3f5',
    padding: '4px 16px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  sizeControlls: {
    position: 'absolute',
    display: 'flex',
    bottom: 5,
    left: 5,
  },
  orderControlls: {
    position: 'absolute',
    display: 'flex',
    bottom: 5,
    right: 10,
  },
}));