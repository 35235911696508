import { Button } from '@mantine/core';
import { IconPrinter } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getPrintStyle } from './PrintStyle';
import { createStyles } from '@mantine/emotion';

const printStyles = createStyles((theme) => ({
  wrapper: {
    display: 'none',
    position: 'absolute',
    ['@media print']: {
      display: 'block',
      height: '100% !important',
      width: '100%',
      overflow: 'visible !important',
    },
  },
}));

export interface PrintButtonProps {
  title: string;
  children: React.ReactNode;
  disabled: boolean | undefined;
  landscape?: boolean | undefined;
}

export const PrintButton: React.FC<PrintButtonProps> = ({
  title,
  children,
  disabled,
  landscape,
}) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  const promiseResolveRef = useRef<any>(null);
  const [data, setData] = useState<React.ReactNode>(null);

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: false,

    pageStyle: getPrintStyle(landscape),
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setData(children);
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
      setData(false);
    },
  });

  const { classes: print } = printStyles();

  return (
    <>
      <div ref={printRef} className={print.wrapper}>
        {data && <>{data}</>}
      </div>
      <Button
        disabled={disabled}
        variant="subtle"
        leftSection={<IconPrinter size={14} />}
        onClick={() => handlePrint()}>
        {title}
      </Button>
    </>
  );
};
