import React, { useEffect, useState } from 'react';
import {
  Stack,
  Title,
  List,
  Space,
  Modal,
  Group,
  Button,
  Stepper,
  Grid,
  Switch,
  Checkbox,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconBan, IconCheck, IconCurrencyDollar, IconMail, IconReport } from '@tabler/icons-react';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { DatePickerInput } from '@mantine/dates';
import { t } from 'ttag';
import { DateTime } from 'luxon';
import { getLocale } from '../../../i18nInit';

interface ApprovalPeriodCompletionModalProps {
  applicationPeriod: any;
  isOpened: boolean;
  onClose: (refresh: boolean) => void;
}

export const ApprovalApplicationPeriodCompletionModal: React.FC<
ApprovalPeriodCompletionModalProps
> = ({ applicationPeriod, isOpened, onClose }) => {
  const api = useApi();
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const { selectedOrganisation } = useSession();

  const form = useForm({
    initialValues: {
      name: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'), //applicationPeriod.title,
      comment: '',
      notifyApplicants: false,
      hasReporting: false,
      reportingDate: DateTime.now().plus({ months: 12 }).toJSDate(),
      confirm: false,
    },
  });

  useEffect(() => {
    if (isOpened) {
      setActive(0);
      form.reset();
    }
  }, [isOpened]);

  const save = async () => {
    const response = await api.completeApplicationPeriod(
      selectedOrganisation!.id,
      applicationPeriod.id,
      {
        name: form.values.name,
        comment: form.values.comment,
        notifyApplicants: form.values.notifyApplicants,
        reportingDueDate: form.values.hasReporting
          ? DateTime.fromJSDate(form.values.reportingDate).toISODate()
          : null,
      },
    );
    if (response.ok) {
      onClose(true);
    }
  };

  return (
    <Modal
      size={'xl'}
      centered
      opened={isOpened}
      onClose={() => onClose(false)}
      title={<Title order={3}>Ta beslut</Title>}>
      <Stack m={'xl'}>
        <Stepper
          radius={'lg'}
          active={active}
          onStepClick={setActive}
          iconSize={32}
          size="sm"
          h={300}>
          <Stepper.Step label="Beslut" allowStepSelect={active > 0}>
            <Stack>
              <Space />
              <TextInput label={t`Namn på beslut`} {...form.getInputProps('name')} />
              <Textarea label={t`Kommentar`} {...form.getInputProps('comment')} />
            </Stack>
          </Stepper.Step>
          <Stepper.Step label="Inställningar" allowStepSelect={active > 1}>
            <Stack align={'flex-start'}>
              <Space />
              <Switch
                label="Jag vill aktivera återrapportering"
                {...form.getInputProps('hasReporting', { type: 'checkbox' })}
              />

              {form.values.hasReporting && (
                <DatePickerInput
                  valueFormat={getLocale().formats.L}
                  label={t`Sista datum för återrapportering`}
                  clearable={true}
                  {...form.getInputProps('reportingDate')}
                />
              )}

              <Switch
                label="Jag vill notifiera ansökare via mail"
                {...form.getInputProps('notifyApplicants', { type: 'checkbox' })}
              />
            </Stack>
          </Stepper.Step>
          <Stepper.Step label="Sammanfattning" allowStepSelect={active > 2}>
            <Stack>
              <Space />
              <List size="sm">
                <List.Item icon={<IconCheck />}>
                  <b>{applicationPeriod.numberOfPreliminaryApprovedApplications}</b> st ansökningar
                  kommer att beviljas.
                </List.Item>
                <List.Item icon={<IconBan />}>
                  <b>{applicationPeriod.numberOfPreliminaryDeclinedApplications}</b> st ansökningar
                  kommer att nekas.
                </List.Item>
                <List.Item icon={<IconCurrencyDollar />}>
                  Totalt <b>{applicationPeriod.preliminaryGrantedAmount}</b> kr kommer att beviljas.
                </List.Item>
                <List.Item icon={<IconMail />}>
                  {form.values.notifyApplicants
                    ? `Totalt ${
                      applicationPeriod.numberOfPreliminaryApprovedApplications +
                        applicationPeriod.numberOfPreliminaryDeclinedApplications
                    } st mail kommer att skickas`
                    : 'Inga mail kommer att skickas till sökanden'}
                </List.Item>
                <List.Item icon={<IconReport />}>
                  {form.values.hasReporting
                    ? `Återrapportering ska ske senast ${DateTime.fromJSDate(
                      form.values.reportingDate,
                    ).toFormat('yyyy-MM-dd')}`
                    : 'Återrapportering är inte aktiverad'}
                </List.Item>
              </List>
              <Space />

              <Checkbox
                label={t`Jag har tagit del av ovanstående information och vill ta ett beslut`}
                {...form.getInputProps('confirm', { type: 'checkbox' })}></Checkbox>
            </Stack>
          </Stepper.Step>
        </Stepper>

        <Grid>
          <Grid.Col offset={3} span={6}>
            <Group justify={'center'}>
              <Button variant="outline" onClick={prevStep} disabled={active == 0}>
                Föregående
              </Button>
              {active < 2 && <Button onClick={nextStep}>Nästa</Button>}
              {active == 2 && (
                <Button disabled={!form.values.confirm} onClick={async () => save()}>
                  Ta beslut
                </Button>
              )}
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group justify={'end'}>
              <Button variant="outline" onClick={() => onClose(false)}>
                Avbryt
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </Stack>
    </Modal>
  );
};
