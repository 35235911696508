import { Box, Drawer, DrawerProps, useMantineTheme, Text, Title } from '@mantine/core';
import React, { ReactElement } from 'react';
import { isPresent } from 'utilitype';
import { HEADER_HEIGHT } from '../../layout/MenuHeader';

export const SessionStorageKeys = {
  ActiveImage: 'active-image',
  ApplicationAttachments: 'application-attachments',
};

export const LocalStorageKeys = {
  ShowAttachmentDrawer: 'show-attachment-drawer',
};

type LiitDrawerProps = {
  children?: React.ReactNode;
  actions?: ReactElement;
  zeroPaddingRight?: boolean;
  titleText?: string;
} & DrawerProps;

export const LiitDrawer: React.FC<LiitDrawerProps> = ({
  children,
  zeroPaddingRight = false,
  titleText,
  withCloseButton,
  ...rest
}) => {
  const { padding } = rest;
  const otherPadding = isPresent(padding) ? padding : 'xl';
  const paddingRight = isPresent(padding) ? padding : zeroPaddingRight ? 0 : 'xl';

  return (
    <Drawer.Root padding={0} position={'right'} zIndex={199} {...rest}>
      <Drawer.Overlay opacity={0.35} blur={1} />
      <Drawer.Content>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}>
          {titleText && (
            <Drawer.Header>
              <Drawer.Title pl={otherPadding} pt={otherPadding}>
                <Title order={3}>{titleText}</Title>
              </Drawer.Title>
              {withCloseButton && <Drawer.CloseButton />}
            </Drawer.Header>
          )}

          <Drawer.Body
            style={{
              maxHeight: 'unset',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              minHeight: 0,
            }}>
            <Box
              pt={otherPadding}
              pb={otherPadding}
              pl={otherPadding}
              pr={paddingRight}
              style={{ flexGrow: 1, display: 'flex', overflowY: 'auto' }}>
              {children}
            </Box>
          </Drawer.Body>
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
};
