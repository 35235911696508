import { Tabs, TabsProps } from '@mantine/core';
import { isMissing } from 'utilitype';
import { CONTAINER_PADDING_WIDTH } from '../../layout/Layout';

interface LiitTabsProps {
  fixedTabWith?: number;
  backgroundColor?: string;
}

type ExtendedProps = LiitTabsProps & TabsProps;

export const LiitTabs: React.FC<ExtendedProps> = ({ fixedTabWith, backgroundColor, ...props }: ExtendedProps) => {
  return (
    <Tabs
      styles={(theme) => ({
        root: {
          position: 'relative',
        },
        tab: {
          padding: '10px 16px',
          height: 42,
          marginBottom: '0px',
          '&:nthOfType(1)': {
            marginLeft: CONTAINER_PADDING_WIDTH - 22,
          },
        },
        panel: {
          // borderTop: `1px solid ${BORDER_COLOR}`,
          backgroundColor: isMissing(backgroundColor) ? 'transparent' : backgroundColor,
          '&:nthOfType(2)': {
            borderTopLeftRadius: 0,
          },
        },

        tabIcon: {
          marginRight: theme.spacing.xs,
          display: 'flex',
          alignItems: 'center',
        },

        tabsList: {
          display: 'flex',
        },
      })}
      {...props}
    />
  );
};