import React from 'react';
import { ActionIcon } from '@mantine/core';

import { useNavigate } from 'react-router-dom';
import { Navigation } from '../layout/Navigation';
import { Routes } from '../layout/Routes';
import { IconArrowLeft } from '@tabler/icons-react';


type BackButtonProps = {
  route?: Routes;
  toId?: string;
};

export const BackButton: React.FC<BackButtonProps> = ({ route, toId }) => {
  const navigate = useNavigate();

  const back = () => {
    if (route) {
      const r = Navigation[route].link + (toId ? `/${toId}` : '');
      navigate(r);
    } else {
      navigate(-1);
    }
  };

  return (
    <ActionIcon
      variant={'subtle'}
      data-testid={'back-to-list-button'}
      pt={2}
      color="blue.7"
      size="md"
      radius="xl"
      onClick={() => back()}>
      <IconArrowLeft size={30} />
    </ActionIcon>
  );
};
