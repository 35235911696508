import { Text } from '@mantine/core';
import { FC } from 'react';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElement } from '../../models/FormElement';

type ViewerInputWrapperWithLabelProps = (ViewerFormElementComponentProps | FormElementComponentProps) & {
  element: FormElement;
  children: React.ReactNode;
  label: string;
};

export const ViewerInputWrapperWithLabel: FC<ViewerInputWrapperWithLabelProps> = ({ number, children, label }) => {
  return (
    <>
      <Text size="sm" fw="bold" pb="0" mb="0">{`${label?.trim() ?? '...'}`}</Text>
      {children}
    </>
  );
};