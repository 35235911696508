/* eslint-disable @typescript-eslint/no-unused-vars */
import { IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import React, { ReactNode, useState } from 'react';
import { RenderCategoryCell } from '../../common/grid-columns/RenderCategoryCell';
import { RenderApplicantCell } from '../../common/grid-columns/RenderApplicantCell';
import { RenderAmountCell } from '../../common/grid-columns/RenderAmountCell';
import { RenderApplicationStatusCell } from '../../common/grid-columns/RenderApplicationStatusCell';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { TextInput } from '@mantine/core';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ApprovalPeriodListColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Ansökningsperiod',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, paddingLeft: 40 },
  },
  {
    displayName: 'Startdatum',
    propertyName: 'startDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Slutdatum',
    propertyName: 'endDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Beviljade',
    propertyName: 'numberOfApprovedApplications',
    propertyType: PropertyType.Number,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Nekade',
    propertyName: 'numberOfDeclinedApplications',
    propertyType: PropertyType.Number,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Beviljat belopp',
    propertyName: 'grantedAmount',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Budget',
    propertyName: 'budget',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
];
