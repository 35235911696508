import React from 'react';
import { applicationAttachmentsStyles } from './applicationAttachmentsStyles';
import { Carousel } from '@mantine/carousel';
import { ActionIcon, Badge, Card, Image, Paper, Stack, Text, Tooltip } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconFile } from '@tabler/icons-react';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { LocalStorageKeys, SessionStorageKeys } from '../../../components/LiitDrawer/LiitDrawer';
import { AttachmentListItem } from '../../../models/AttachmentListItem';
import { AttachmentDisplayFormat } from '../../../models/AttachmentDisplayFormat';
import { AttachmentType } from '../../../models/AttachmentType';
import { t } from 'ttag';
import { IconFileTypePdf } from '@tabler/icons-react';

export type ApplicationAttachmentProps = {
  attachments: Array<AttachmentListItem>;
};

const PLACEHOLDER_IMAGE = '/img-placeholder.jpg';

export const ApplicationAttachments: React.FC<ApplicationAttachmentProps> = ({ attachments }) => {
  const { classes, theme } = applicationAttachmentsStyles();

  const [showAttachmentDrawer, setShowAttachmentDrawer] = useLocalStorage({
    key: LocalStorageKeys.ShowAttachmentDrawer,
    defaultValue: false,
  });

  const [, setActiveAttachment] = useSessionStorage<string>({
    key: SessionStorageKeys.ActiveImage,
  });

  const getImage = (attachment: AttachmentListItem) => {
    const url = attachment.urls?.find((x) => x.format == AttachmentDisplayFormat.Thumbnail)?.url;

    if (url) {
      return <Image
        height={80}
        style={{ cursor: 'pointer' }}
        src={url}
        w="auto"
        fit="contain"
        //withPlaceholder={!url}
        onClick={() => {
          setActiveAttachment(attachment.id as string);
        }} />;
    } else {
      return <Image
        height={80}
        width={120}
        style={{ cursor: 'pointer' }}
        fit="contain"
        //withPlaceholder={!url}
        onClick={() => {
          setActiveAttachment(attachment.id as string);
        }} />;
    }
  };

  const getAttachment = (attachment: AttachmentListItem): React.ReactNode => {
    if (attachment.type == AttachmentType.Image) {
      return (
        <Carousel.Slide key={attachment.id} className={classes.centerCard}>
          <Stack gap={0} align='center' style={{
            maxWidth: '180px',
            width: '100%',
          }}>
            <Paper withBorder p={4}>
              {getImage(attachment)}
            </Paper>
            <Text size={'xs'} truncate="end" w={'inherit'} ta='center'>
              <Tooltip withArrow label={attachment.fileName} position="top-start">
                <span>{attachment.fileName}</span>
              </Tooltip>
            </Text>
          </Stack>
        </Carousel.Slide>
      );
    } else if (attachment.type == AttachmentType.Pdf) {
      return (
        <Carousel.Slide key={attachment.id} className={classes.centerCard}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '180px',
              width: '100%',
            }}>
            <Card p={'xs'} shadow={'sm'} withBorder>
              <ActionIcon
                variant={'subtle'}
                color={theme.primaryColor}
                size={64}
                onClick={() => setActiveAttachment(attachment.id as string)}>
                <IconFile size={48} />
              </ActionIcon>
            </Card>
            <Text size={'xs'} truncate="end" w={'inherit'} ta='center'>
              <Tooltip withArrow label={attachment.fileName} position="top-start">
                <span>{attachment.fileName}</span>
              </Tooltip>
            </Text>
          </div>
        </Carousel.Slide>
      );
    }
  };
  return (
    <div>
      <div
        onClick={() => setShowAttachmentDrawer(!showAttachmentDrawer)}
        className={classes.attachmentsContainer}>
        {showAttachmentDrawer ? <IconChevronDown size={'16px'} /> : <IconChevronUp size={'16px'} />}
        <Text size="sm">{t`Bifogade filer`}</Text>
        <Badge color="orange">{attachments.length}</Badge>
      </div>
      {showAttachmentDrawer && (
        <Carousel
          withIndicators
          height={152}
          pl={24}
          pr={24}
          slideSize={'25%'}
          slideGap={'md'}
          loop
          align="start"
        >

          {attachments.map((a) => getAttachment(a))}
        </Carousel>
      )}
    </div>
  );
};
