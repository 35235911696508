import {
  DirectionProvider,
  MantineProvider,
} from '@mantine/core';
import { Router } from './Router';
import { getLocale, setLocale } from './i18nInit';
import { ModalsProvider } from '@mantine/modals';
import { SessionProvider } from './providers/SessionProvider';
import { MantineEmotionProvider } from '@mantine/emotion';
import { DatesProvider } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import { Locals } from './locals';
import { customTheme } from './theme';

export const TOP_PADDING = 32;
export const INPUT_MAX_LENGTH = 200;

const App = () => {
  setLocale(Locals.sv);

  return (
    <MantineProvider theme={customTheme}>
      <Notifications position="top-center" />
      <MantineEmotionProvider>
        <DirectionProvider>
          <DatesProvider settings={{ locale: getLocale().name }}>
            <ModalsProvider>
              <SessionProvider>
                <Router />
              </SessionProvider>
            </ModalsProvider>
          </DatesProvider>
        </DirectionProvider>
      </MantineEmotionProvider>
    </MantineProvider>
  );
};

export default App;
