import { Flex, Grid, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import React from 'react';
import { t } from 'ttag';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';

export const ViewerContactPerson: React.FC<ViewerFormElementComponentProps> = ({
  number,
  element,
}) => {
  const { isByProxy, contactPersonFirstName, contactPersonLastName } = element.value;

  if (!isByProxy) {
    return null;
  }

  return (
    <Flex direction={'column'}>
      <Flex gap={8} align={'center'}>
        <IconCheck size={24} color="green" />
        <Text size={'sm'} fw={'bold'}>{t`Jag är ombud för en annan person`}</Text>
      </Flex>

      <Grid gutter={'lg'} pt={16}>
        <Grid.Col span={{ lg: 6 }}>
          <Text size="md" fw="bold" pb="0" mb="0">{t`Förnamn`}</Text>
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {contactPersonFirstName}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <Text size="md" fw="bold" pb="0" mb="0">{t`Förnamn`}</Text>
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {contactPersonLastName}
          </Text>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};
