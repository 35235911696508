import React, {  } from 'react';
import { Group, Badge, ActionIcon, useMantineTheme } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { Application } from '../../../models/Application';
import { Tag } from '../../../models/Tag';

interface ApplicationTagsProps {
  application: Application;
  removeTag: (tag: Tag) => void
}
  
export const ApplicationTags: React.FC<ApplicationTagsProps> = ({
  application,
  removeTag,
}) => {

  const theme = useMantineTheme();

  return (
    <Group justify={'end'} gap={'xs'}>

      {application.tags.map((tag) => (
        <Badge key={tag.id} size="md" color={tag.color} radius="md" variant="dot" 
          rightSection={
            <ActionIcon size="xs" color="dark" variant="transparent" 
              onClick={async () => { 
                removeTag(tag);
              }}>
              <IconX size={12} />
            </ActionIcon>          
          }>
          {tag.name}
        </Badge>
      ))}
    
  </Group>
  );
};
