import { Input, Grid, Group, Space, Stack } from '@mantine/core';
import {
  IconArrowLeft,
  IconArrowRight,
  IconMinus,
  IconPlus,
  IconSettings,
  IconTrash,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import { FormBuilderPropertyView } from '../../FormBuilderPropertyView';
import { FormElement } from '../../models/FormElement';
import { FormElementType } from '../../models/FormElementType';
import { RepeaterToolbox } from './RepeaterToolbox';
import { RepeaterElements, repeaterTypedInputTypes } from './RepeaterToolBoxItems';
import { FormElementComponent } from '../../FormElementComponent';
import { editorStyles } from './editorStyles';
import {
  getGenericDefaults,
  getElementWithOptionsDefaults,
  getTextAreaDefaults,
  getTypedTextBoxDefaults,
} from '../ElementDefaultOptions';
import { isMissing, isPresent } from 'utilitype';
import { getNewSize } from '../../ElementSizeCalculator';
import { LiitActionIcon } from '../../../LiitActionIcon/LiitActionIcon';
import { t } from 'ttag';
import { reorderArray } from '../../../../utils/reorderArray';
import { LiitDrawer } from '../../../LiitDrawer/LiitDrawer';
import LanguageHandler from '../../../../utils/languageHandler';
import { LanguageCode } from '../../../../models/LanguageCode';
import { RepeaterProps } from './Repeater';
import { InputTypesSetting } from '../../FormBuilderToolbox/ToolboxItemSettings';
import { TypedTextBoxTypeItems } from '../TypedTextBox/TypedTextBoxTypeItems';

export const RepeaterEditor: React.FC<RepeaterProps> = ({
  element,
  mode,
  triggerSave,
}) => {
  const [propertyEditorOpened, setPropertyEditorOpened] = useState(false);
  const [selectedElement, setSelectedElement] = useState<FormElement & any>();
  const { classes } = editorStyles();

  const elementUpdated = () => {
    if (triggerSave) {
      triggerSave();
    }
  };

  const renderElement = (ele: FormElement) => {
    return <FormElementComponent element={ele} mode={mode} />;
  };

  const appendElement = (defaultElement: FormElement) => {
    element.template?.push(defaultElement);
  };

  const addElementTypeMap: { [key in RepeaterElements]: () => void } = {
    [FormElementType.TypedTextBox]: () => appendElement(getTypedTextBoxDefaults(FormElementType.TypedTextBox)),
    [FormElementType.TextArea]: () => appendElement(getTextAreaDefaults(FormElementType.TextArea)),
    [FormElementType.Dropdown]: () =>
      appendElement(getElementWithOptionsDefaults(FormElementType.Dropdown)),
    [FormElementType.RadioButtonGroup]: () =>
      appendElement(getElementWithOptionsDefaults(FormElementType.RadioButtonGroup)),
    [FormElementType.CheckboxGroup]: () =>
      appendElement(getElementWithOptionsDefaults(FormElementType.CheckboxGroup, [])),
    [FormElementType.FileGroup]: () =>
      appendElement(getGenericDefaults(FormElementType.FileGroup, null)),
    [FormElementType.DatePickerElement]: () =>
      appendElement(getGenericDefaults(FormElementType.DatePickerElement, null)),
  };

  const addElement = (elementType: FormElementType) => {
    addElementTypeMap[elementType]();
    elementUpdated();
  };

  const selectElement = (el: FormElement) => {
    setSelectedElement(el);
    setPropertyEditorOpened(true);
  };

  const deleteElement = (el: FormElement) => {
    element.template = element.template?.filter((e) => e.id !== el.id);
    elementUpdated();
  };

  const updateElement = (el: FormElement) => {
    if (isMissing(element.template)) {
      return;
    }
    const index = element.template.findIndex((x) => x.id === el.id);
    element.template[index] = el;

    setSelectedElement(el);

    setPropertyEditorOpened(false);
    elementUpdated();
  };

  const getAvailableInputTypes = (elementToEdit: FormElement): InputTypesSetting[] | undefined => {
    if (elementToEdit.type !== FormElementType.TypedTextBox) {
      return undefined;
    }

    const result = repeaterTypedInputTypes.map((inputType) => TypedTextBoxTypeItems[inputType]);

    return result;
  };

  const label = LanguageHandler.getTextByLanguage(element.label, LanguageCode.sv);
  const descriptionText = LanguageHandler.getTextByLanguage(element.description, LanguageCode.sv);

  return (
    <>
      <Input.Wrapper
        label={label}
        description={descriptionText}
        size={'md'}
        styles={{ label: { paddingBottom: 8 } }}>
        <Grid>
          <Grid.Col span={12}>
            <Group justify={'center'}>
              <RepeaterToolbox addElement={addElement} />
            </Group>
          </Grid.Col>
          {element.template?.map((el, index) => {
            return (
              <Grid.Col span={{ md: 12, lg: el.size }} key={`element_${el.id}_${index}`}>
                <div className={classes.wrapper}>
                  <Stack
                    key={element.id}
                    style={{ width: '100%' }}
                    gap="xs"
                    onClick={() =>
                      element.type != FormElementType.Repeater ? selectElement(element) : null
                    }>
                    {renderElement(el)}
                    <Space h={'lg'} />
                  </Stack>
                  <div className={classes.editorButtons}>
                    <LiitActionIcon
                      tooltip={'Inställningar'}
                      icon={<IconSettings size={16} />}
                      variant="subtle"
                      onClick={() => selectElement(el)}
                    />
                    <LiitActionIcon
                      tooltip={'Ta bort'}
                      icon={<IconTrash size={16} />}
                      variant="subtle"
                      onClick={() => deleteElement(el)}
                    />
                  </div>

                  <div className={classes.sizeControlls}>
                    <LiitActionIcon
                      tooltip={t`Minska storlek`}
                      icon={<IconMinus size={16} />}
                      onClick={() => {
                        const newSize = getNewSize(false, el.size);

                        if (isPresent(newSize)) {
                          el.size = newSize;
                          elementUpdated();
                        }
                      }}
                    />
                    <LiitActionIcon
                      tooltip={t`Öka storlek`}
                      icon={<IconPlus size={16} />}
                      onClick={() => {
                        const newSize = getNewSize(true, el.size);

                        if (isPresent(newSize)) {
                          el.size = newSize;
                          elementUpdated();
                        }
                      }}
                    />
                  </div>

                  <div className={classes.orderControlls}>
                    <LiitActionIcon
                      tooltip={t`Flytta vänster`}
                      icon={<IconArrowLeft size={16} />}
                      onClick={() => {
                        reorderArray(element.template ?? [], index, index - 1);
                        elementUpdated();
                      }}
                    />
                    <LiitActionIcon
                      tooltip={t`Flytta höger`}
                      icon={<IconArrowRight size={16} />}
                      onClick={() => {
                        reorderArray(element.template ?? [], index, index + 1);
                        elementUpdated();
                      }}
                    />
                  </div>
                </div>
              </Grid.Col>
            );
          })}
        </Grid>
      </Input.Wrapper>
      <LiitDrawer
        opened={propertyEditorOpened}
        onClose={() => setPropertyEditorOpened(false)}
        size={'md'}>
        {selectedElement && (
          <FormBuilderPropertyView
            selectedElement={selectedElement}
            apply={updateElement}
            close={() => setPropertyEditorOpened(false)}
            availableInputTypes={getAvailableInputTypes(selectedElement)}
          />
        )}
      </LiitDrawer>
    </>
  );
};
