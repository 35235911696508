import { NumberInput, NumberInputProps } from '@mantine/core';
import React from 'react';

interface LocaleData {
  thousandMatcher: string,
}

export const LocaleInformation: Record<string, LocaleData> = {
  ['sv-SE']: { thousandMatcher: ' ' },
  ['en-GB']: { thousandMatcher: ',' },
  ['es']: { thousandMatcher: '.' },
};

type LiitNumberInputProps = {
  textAlign?: 'right' | 'left',
} & NumberInputProps & React.RefAttributes<HTMLInputElement>;

const LOCAL = 'sv-SE';

export const LiitNumberInput: React.FC<LiitNumberInputProps> = ({ textAlign, ...props  }) => {
  return (
    <NumberInput
      allowNegative={false}
      thousandSeparator={LocaleInformation[LOCAL].thousandMatcher}
      styles={{
        input: {
          textAlign: textAlign ?? 'left',
        },
      }}
      {...props}
    />
  );
};
