import { Radio, Stack } from '@mantine/core';
import { FC } from 'react';
import { FormElementOption } from '../../models/FormElementOption';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { ViewerRadioButtonGroup } from './ViewerRadioButtonGroup';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormLabel } from '../Common/FormLabel';
import { FormElementWithControlQuestions } from '../../models/FormElementWithControlQuestion';

const mapOptions = (
  id: string,
  option: FormElementOption,
  labelAndTextPosition: 'left' | 'right' | undefined,
) => {
  const { label, id: optionsId } = option;

  const optionLabel = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);

  return (
    <Radio
      styles={{
        body: { flexDirection: labelAndTextPosition === 'right' ? 'row-reverse' : 'row', gap: 12 },
        label: { padding: 0 },
      }}
      key={`${id}_rb_${optionsId}`}
      value={optionsId}
      label={optionLabel}
    />
  );
};

type RadioButtonGroupProps = {
  element: FormElementWithControlQuestions;
} & FormElementComponentProps;

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerRadioButtonGroup number={number} element={element} />;
  }

  const { description, required, id, options, hideLabel, labelAndTextPosition } = element;

  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);

  const value = element.value as string;

  return (
    <Radio.Group
      styles={{
        error: {
          paddingTop: '4px',
        },
      }}
      description={descriptionText}
      label={
        hideLabel ? undefined : (
          <FormLabel element={element} mode={mode} justify={'space-between'} />
        )
      }
      required={required}
      withAsterisk={false}
      value={value}
      error={error}
      onChange={(changedValue: string) => {
        if (onChange) {
          onChange(changedValue ?? '');
        }
      }}>
      <Stack gap={8} pt={8}>
        {options.map((option) => mapOptions(id, option, labelAndTextPosition))}
      </Stack>
    </Radio.Group>
  );
};
