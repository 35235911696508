import { Box, Divider, Flex, Grid, Group, Text } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { FormElementComponent } from '../../FormElementComponent';
import { FormElementMode } from '../../FormElementMode';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElement } from '../../models/FormElement';
import { FormElementRepeater } from '../../models/FormElementRepeater';
import { LanguageCode } from '../../../../models/LanguageCode';
import Formatter from '../../../../utils/formatter';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementType } from '../../models/FormElementType';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { TextBoxInputType } from '../TypedTextBox/TextBoxInputType';

export const ViewerRepeater: React.FC<ViewerFormElementComponentProps> = ({ element, number }) => {
  const groups = element.value as FormElement[][];
  const repeater = element as FormElementRepeater;

  if (isMissing(groups)) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        {t`Inga värden satta`}
      </ViewerInputWrapper>
    );
  }

  const getSummary = () => {
    const hasNumberFields = element.template
      .filter((x) => x.type === FormElementType.TypedTextBox)
      .some((x) => (x as FormElementTypedTextBox).inputType === TextBoxInputType.Number);

    if (!hasNumberFields) {
      return null;
    }

    return (
      
        <div>
        <Divider size={'xs'} mt={8} pb={8} />
        <Flex direction={'column'} align={'end'}>
          {element.template
            .map((el, elementIndex) => {
              if (
                el.type === FormElementType.TypedTextBox &&
                (el as FormElementTypedTextBox).inputType === TextBoxInputType.Number
              ) {
                return (
                  <Group key={el.id} maw={350} w={'100%'} justify={'space-between'} gap={0}>
                    <Text size={'sm'} fw={600} style={{ whiteSpace: 'nowrap' }}>
                      {t`Summa`} {LanguageHandler.getTextByLanguage(el.label, LanguageCode.sv)}
                    </Text>
                    <Text size={'sm'} fw={600}>
                      {Formatter.formatNumber(
                        (element.value as FormElement[][]).reduce(
                          (a, b) => a + Number(b[elementIndex].value),
                          0,
                        ),
                      )}
                    </Text>
                  </Group>
                );
              }
            })
            .filter((x) => x)}
        </Flex>
        </div>
      
    );
  };

  return (
    <ViewerInputWrapper number={number} element={element}>
      <Divider size={'xs'} pb={8} mt={8} />
      {groups.map((group, groupIndex) => (
        <Box key={`repeater_${number}_group_${groupIndex}`}>
          <Grid>
            {group.map((childElement) => {
              if (repeater.labelsOnFirstGroup && groupIndex > 0) {
                childElement.hideLabel = true;
              }
              return (
                <Grid.Col span={{ md: 12, lg: childElement.size }}
                  key={`repeater_${number}_group_${groupIndex}_element_${childElement.id}`}>
                  <FormElementComponent element={childElement} mode={FormElementMode.Viewer} />
                </Grid.Col>
              );
            })}
          </Grid>
        </Box>
      ))}
      {repeater.sumNumberFields && groups.length > 0 && getSummary()}
      {groups.length === 0 && (
        <Text color={'dimmed'} size={'sm'}>
          {t`Saknas`}
        </Text>
      )}
    </ViewerInputWrapper>
  );
};
