import { Progress, Text, Grid } from '@mantine/core';
import { IconMoneybag } from '@tabler/icons-react';
import React from 'react';
import { Dashboard } from '../../../models/Dashboard';
import { DashboardCard } from './DashboardCard';
import Formatter from '../../../utils/formatter';
import { HoverTooltip } from '../../../components/HoverTooltip';

interface BudgetCardProps {
  data: Dashboard;
}

export const BudgetCard: React.FC<BudgetCardProps> = ({ data }) => {
  const getTotalBudget = () => {
    return data.applicationPeriods.reduce((sum, item) => sum + (item.budget ?? 0), 0);
  };

  const getTotalGranted = () => {
    return data.applicationPeriods.reduce(
      (sum, item) => sum + (item.grantedAmount ?? 0) + (item.preliminaryGrantedAmount ?? 0),
      0,
    );
  };

  const getTotalPercent = () => {
    return getTotalBudget() === 0 ? 0 : (getTotalGranted() / getTotalBudget()) * 100;
  };

  const getContent = () => {
    return (
      <>
        <Text fz={32} fw={700}>
          {Formatter.formatNumber(getTotalPercent())} %
        </Text>
        <Text color="dimmed">
          {Formatter.formatCurrency(getTotalGranted(), '0')} av{' '}
          {Formatter.formatCurrency(getTotalBudget(), '0')} beviljat
        </Text>
        <Progress value={getTotalPercent()} mt="md" size="lg" radius="xl" color="orange.5" />
      </>
    );
  };

  const getCollapseContent = () => {
    if (data.applicationPeriods.length === 0) return null;

    return (
      <Grid gutter={'xs'}>
        {data.applicationPeriods.map((period) => (
          <React.Fragment key={'reviewcard_' + period.id}>
            <Grid.Col span={7}><Text size='sm'>{period.title}</Text></Grid.Col>
            <Grid.Col span={2}>
            <Text size='sm'>
              {Formatter.formatNumber(
                ((period.preliminaryGrantedAmount + period.grantedAmount) / period.budget) * 100,
              )}{' '}
              %
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <HoverTooltip
                content={`${Formatter.formatCurrency(
                  period.preliminaryGrantedAmount + period.grantedAmount,
                  '0',
                )} av ${Formatter.formatCurrency(period.budget, '0')} beviljat`}>
                <Progress
                  value={
                    ((period.preliminaryGrantedAmount + period.grantedAmount) / period.budget) * 100
                  }
                  size="lg"
                  radius="xl"
                  color="orange.5"
                />
              </HoverTooltip>
            </Grid.Col>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  return (
    <DashboardCard
      title="Budget"
      icon={IconMoneybag}
      content={getContent()}
      collapse={getCollapseContent()}
    />
  );
};
