import { createStyles } from '@mantine/emotion';

export const runnerStyles = createStyles((theme) => ({
  elementsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  components: {
    flex: 1,
    margin: 0,
  },
}));