import { Grid, TextInput } from '@mantine/core';
import { FC } from 'react';
import { t } from 'ttag';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { ContactPersonOrganizationValue } from './ContactPersonOrganizationValue';
import { ViewerContactPersonOrganization } from './ViewerContactPersonOrganization';

type ContactPersonOrganizationProps = {
  element: FormElement;
} & FormElementComponentProps;

export const ContactPersonOrganization: FC<ContactPersonOrganizationProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerContactPersonOrganization number={number} element={element} />;
  }

  const value = element.value as ContactPersonOrganizationValue;

  const { contactPersonFirstName, contactPersonLastName } = value;

  return (
    <Grid gutter={'lg'}>
      <Grid.Col span={{ lg: 6 }}>
        <TextInput
          disabled={mode === FormElementMode.Editor}
          label={t`Förnamn`}
          required={true}
          value={contactPersonFirstName}
          error={error}
          onChange={(event) => {
            if (onChange) {
              onChange({ ...value, contactPersonFirstName: event.currentTarget.value ?? '' });
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ lg: 6 }}>
        <TextInput
          disabled={mode === FormElementMode.Editor}
          label={t`Efternamn`}
          required={true}
          value={contactPersonLastName}
          error={!!error}
          onChange={(event) => {
            if (onChange) {
              onChange({ ...value, contactPersonLastName: event.currentTarget.value ?? '' });
            }
          }}
        />
      </Grid.Col>
    </Grid>
  );
};
