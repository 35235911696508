import { createStyles } from '@mantine/emotion';

export const LiitFormContainerStyles = createStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
    overflow: 'hidden',
  },

  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
