import React, { useEffect, useState } from 'react';
import { Stack, Title, Modal, Group, Button, Grid, Text, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { BankAccount } from '../../models/BankAccount';

interface PaymentModalProps {
  paymentIds: string[];
  isOpened: boolean;
  onClose: (paymentFileId: string | null) => void;
}

export const PaymentModal: React.FC<PaymentModalProps> = ({ paymentIds, isOpened, onClose }) => {
  const api = useApi();
  const { selectedOrganisation } = useSession();
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string | null>(null);
  const form = useForm({
    initialValues: {
      bankAccountId: '',
    },
  });

  const refreshBankAccounts = async () => {
    const result = await api.getBankAccounts(selectedOrganisation!.id);
    setBankAccounts(result);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refreshBankAccounts();
    };

    if (isOpened) {
      form.reset();
      fetchData();
    }
  }, [isOpened]);

  const save = async () => {
    const response = await api.createPaymentBatch(selectedOrganisation!.id, {
      paymentIds: paymentIds,
      bankAccountId: form.values.bankAccountId,
    });

    if (response.ok) {
      const json = await response.json();
      onClose(json.id);
    } else {
      onClose(null);
    }
  };

  return (
    <Modal
      size={'md'}
      centered
      opened={isOpened}
      onClose={() => onClose(null)}
      title={<Title order={3}>Skapa betalfil</Title>}>
      <Stack m={'xl'}>
        {(!bankAccounts || bankAccounts.length === 0) && (
          <>
            <Text>
              Bankonto saknas. Gå till Inställningar/Bankkonto för att lägga upp ett bankkonto.
            </Text>
            <Group justify={'center'}>
              <Button onClick={() => onClose(null)} mt="md">
                OK
              </Button>
            </Group>
          </>
        )}

        {bankAccounts && bankAccounts.length > 0 && (
          <>
            <Select
              label="Välj ett bankkonto"
              {...form.getInputProps('bankAccountId')}
              data={bankAccounts.map((x) => ({ value: x.id, label: x.name }))}
            />

            <Group justify={'center'}>
              <Button disabled={!form.values.bankAccountId} onClick={async () => save()}>
                OK
              </Button>

              <Button variant="outline" onClick={() => onClose(null)}>
                Avbryt
              </Button>
            </Group>
          </>
        )}
      </Stack>
    </Modal>
  );
};
