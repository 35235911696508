import { createStyles } from '@mantine/emotion';

export const completionCommentStyles = createStyles((theme) => ({
  commentItem: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
  },
  chatBubble: {
    borderRadius: theme.radius.sm,
    maxWidth: '75%',
    padding: '8px 12px',
  },
  chatLeftSide: {
    backgroundColor: theme.white,
  },
  chatRightSide: {
    backgroundColor: theme.colors.blue[1],
  },
  scrollArea: {
    height: '200px',
    backgroundColor: theme.colors.gray[1],
    borderRadius: theme.radius.sm,
    padding: '8px',
  },
  systemMessage: {
    borderTop: `1px solid ${theme.colors.gray[5]}`,
    borderBottom: `1px solid ${theme.colors.gray[5]}`,
    paddingTop: 4,
    paddingBottom: 4,
    marginTop: 8,
    marginBottom: 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
