import { Accordion, Box, Group, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { ApplicationReviewModel } from '../../../models/ApplicationReviewModel';
import { ReviewScore } from '../../common/application/ReviewScore';
import { t } from 'ttag';
import { ApplicationReviewStatus } from '../../../models/ApplicationReviewStatus';
import Formatter from '../../../utils/formatter';
import { Role } from '../../../models/Role';

interface ApplicationToolbarItemReviewsProps {
  applicationId: string;
  triggerOnChange?: ApplicationReviewStatus | null;
}

export const ApplicationToolbarItemReviews: React.FC<ApplicationToolbarItemReviewsProps> = ({
  applicationId,
  triggerOnChange,
}) => {
  const api = useApi();
  const { selectedOrganisation } = useSession();

  const [reviews, setReviews] = useState<ApplicationReviewModel[]>([]);

  useEffect(() => {
    const fetchData = async (organisationId: string): Promise<void> => {
      const result = await api.getApplicationReviews(organisationId, applicationId);

      setReviews(result);
    };

    if (
      selectedOrganisation &&
      (selectedOrganisation.role === Role.Administrator ||
        selectedOrganisation.role === Role.Approver)
    ) {
      fetchData(selectedOrganisation.id);
    }
  }, [applicationId, triggerOnChange]);

  const hasReviews = reviews && reviews.length > 0;

  if (!selectedOrganisation) {
    return <></>;
  }

  if (selectedOrganisation.role !== Role.Administrator && selectedOrganisation.role !== Role.Approver) {
    return <></>;
  }

  if (!hasReviews) {
    return (
      <Group justify={'center'}>
        <Text c={'dimmed'} size={'sm'}>{t`Inga granskningar gjorda`}</Text>
      </Group>
    );
  }

  const nameAndAmount = (review: ApplicationReviewModel) => {
    return (
      <Group key={review.reviewerName} justify={'space-between'}>
        <Text size={'sm'}>{review.reviewerName}</Text>
        <Text size={'sm'}>
          {review.status === ApplicationReviewStatus.Biased
            ? t`JÄV`
            : review.suggestedAmount
              ? Formatter.formatCurrency(review.suggestedAmount)
              : '-'}
        </Text>
      </Group>
    );
  };

  if (!reviews.some((x) => x.scores.length > 0)) {
    return (
      <Box>
        <Group justify={'space-between'} pb={4}>
          <Text fw={'bolder'}>Namn</Text>
          <Text fw={'bolder'} pr={8}>
            {t`Förslag`}
          </Text>
        </Group>
        <Box pr={8}>{reviews.map((review) => nameAndAmount(review))}</Box>
      </Box>
    );
  }

  return (
    <Box>
       {/* <Group justify={'space-between'}>
        <Text weight={'bold'}>Granskare</Text>
        <Text weight={'bold'} pr={48}>
          {t`Förslag`}
        </Text>
      </Group> */}
      <Accordion variant={'filled'} p={0}>
        {reviews.map((review) => (
          <Accordion.Item
            key={`accordionItem_${review.reviewerName}`}
            value={review.reviewerName}
            p={0}>
            <Accordion.Control p={'xs'} pt={'xs'} pb={'xs'}>
              {nameAndAmount(review)}
            </Accordion.Control>
            <Accordion.Panel p={0}>
                <Stack gap={'xs'} p={0}>
                  <Text size="sm">
                {review.comment && (
                  <Text>{t`Kommentar: `}{review.comment}</Text>
                )}
                  </Text>
                <ReviewScore review={review} />
                </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Box>
  );
};
