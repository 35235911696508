import React from 'react';
import { Stack, Title, Box } from '@mantine/core';
import { GrantMeContainer } from './GrantMeContainer';
import { GrantMeTitleContainer } from './GrantMeTitleHeader';

interface PageViewProps {
  children: React.ReactNode
}

interface PageHeaderProps {
  children: React.ReactNode
}

interface PageBodyProps {
  children: React.ReactNode
  enableMaxWidth?: boolean | undefined;
}

const PAGE_MAX_WIDTH = 800;

const PageHeader: React.FC<PageHeaderProps> = ({ children }) => {

  const getHeaderContent = () => {

    if (typeof children === 'string') {
      return <Title order={3}>{children}</Title>;
    }

    return children;

  };

  return (
    <GrantMeTitleContainer>
      {getHeaderContent()}
    </GrantMeTitleContainer>
  );
};

const PageBody: React.FC<PageBodyProps> = ({ children, enableMaxWidth }) => {
  return (
    <Box p={'xl'} maw={enableMaxWidth ? PAGE_MAX_WIDTH : undefined}>
      <Box pl={'md'}>
        {children}
      </Box>
    </Box>
  );
};

const Page: React.FC<PageViewProps> & { Header: typeof PageHeader, Body: typeof PageBody } = (props: PageViewProps) => {

  const { children } = props;

  return (
    <GrantMeContainer m={'xl'}>
      <Stack>
        {children}
      </Stack>
    </GrantMeContainer>
  );
};

Page.Header = PageHeader;
Page.Body = PageBody;

export const PageView = Page;