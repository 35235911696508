import { Input, Text, Group, Space, Paper } from '@mantine/core';
import React, { FC } from 'react';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { ViewerAmountsTable } from './ViewerAmountsTable';
import { isMissing, isNumber, isPresent } from 'utilitype';
import { formatValue } from '../../../LiitGrid/FormatValue';
import { PropertyType } from '../../../LiitGrid/PropertyType';
import { sumAmountsTableValue } from './SumAmountsTable';
import { amountsTableUpdateValue } from './AmountsTableUpdateValue';
import { AmountsTableValue } from './AmountsTableValue';
import { LiitNumberInput } from '../../../LiitNumberInput/LiitNumberInput';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElement } from '../../models/FormElement';
import { FormLabel } from '../Common/FormLabel';

type AmountsTableProps = {
  element: FormElement
} & FormElementComponentProps;

export const AmountsTable: FC<AmountsTableProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerAmountsTable number={number} element={element} />;
  }
  const { description, required, id, options, hideLabel } = element;
  
  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);

  const value = element.value as AmountsTableValue;

  return (
    <Input.Wrapper
      key={id}
      size={'sm'}
      description={descriptionText}
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} justify={'space-between'} />}
      required={required}
      withAsterisk={false}
      error={error}>
      <Paper withBorder p={'lg'}>
        {options.map((option, index) => (
          <React.Fragment key={`${id}_tb_${option.id}`}>
            <Group justify={'space-between'} style={{ gap: 0 }}>
              <Text size={'sm'}>{LanguageHandler.getTextByLanguage(option.label, LanguageCode.sv)}</Text>
              <LiitNumberInput
                textAlign={'right'}
                size={'xs'}
                hideControls
                disabled={mode === FormElementMode.Editor}
                value={value[option.id]}
                onChange={(event) => {
                  if (isMissing(onChange)) {
                    return;
                  }

                  const updatedValue = amountsTableUpdateValue(value, isNumber(event) ? event : undefined, option.id);

                  onChange(updatedValue);
                }}
              />
            </Group>
            {index < options.length - 1 && <Space h={'xs'} />}
          </React.Fragment>
        ))}
      </Paper>
      <Group justify={'space-between'} pt={'xs'}>
        <Text size={'sm'} fw={600}>
          Totalt
        </Text>
        <Text size={'sm'} fw={600}>
          {formatValue(sumAmountsTableValue(value), PropertyType.Number)}
        </Text>
      </Group>
      <Space h={'lg'} />
    </Input.Wrapper>
  );
};
