import { useIsAuthenticated } from '@azure/msal-react';
import { Stack, Title, Button, Box, Flex, Group, Checkbox } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { FormElementType } from '../../components/formbuilder/models/FormElementType';
import { FormModel } from '../../components/formbuilder/models/FormModel';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { LanguageCode } from '../../models/LanguageCode';
import { download } from '../../utils/download';
import { handleError } from '../../utils/handleError';

interface ExportApplicationsProps {
  applicationPeriod: ApplicationPeriod;
  onComplete: () => void;
}

export const ExportApplications: React.FC<ExportApplicationsProps> = ({
  applicationPeriod,
  onComplete,
}) => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const [formModel, setFormModel] = useState<FormModel>();

  const [staticFields, setStaticFields] = useState<string[]>([]);
  const [formFields, setFormFields] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrganisation) {
        if (applicationPeriod) {
          const result = await api.getApplicationPeriodForm(
            selectedOrganisation.id,
            applicationPeriod.id,
          );
          setFormModel(result);
        }
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [selectedOrganisation, isAuthenticated]);

  // const complete = async (data: string, applicant: ApplicantForm) => {
  //   onComplete();
  // };

  const exportApplications = async () => {
    if (selectedOrganisation && applicationPeriod) {
      const response = await api.exportApplications(selectedOrganisation.id, {
        applicationPeriodId: applicationPeriod.id,
        staticFields: staticFields,
        formFields: formFields,
      });

      if (response.ok) {
        const buffer = await response.arrayBuffer();
        const url = window.URL.createObjectURL(new Blob([buffer]));
        await download(url, 'export.xlsx');
      } else {
        await handleError(response);
      }
    }
  };

  const getElements = () => {
    if (formModel) {
      const elements = formModel.pages.flatMap((page) => page.elements);
      return elements.filter(
        (x) =>
          x.type == FormElementType.TextArea ||
          x.type == FormElementType.Dropdown ||
          x.type == FormElementType.RadioButtonGroup,
      );
    }
    return [];
  };

  return (
    <Flex direction={'column'} w={'100%'}>
      <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Stack>
          <Title order={3}>Exportera</Title>
          Välj de fält du vill exportera:
          <Stack>
            <Checkbox.Group
              label="Sökande/Kontaktperson"
              value={staticFields}
              onChange={setStaticFields}>
              <Stack gap={8} pt={8}>
                <Checkbox value="ApplicantFirstName" label="Förnamn" />
                <Checkbox value="ApplicantLastName" label="Efternamn" />
                <Checkbox
                  value="ApplicantIdentityNumber"
                  label="Person- eller organisationsnummer"
                />
                <Checkbox value="ApplicantClearingNumber" label="Clearingnummer" />
                <Checkbox value="ApplicantAccountNumber" label="Kontonummer" />
                <Checkbox value="ApplicantBgNumber" label="Bankgironummer" />
                <Checkbox value="ApplicantPgNumber" label="Postgironummer" />
                <Checkbox value="ContactPersonEmail" label="E-post" />
                <Checkbox value="ContactPersonPhone" label="Telefon" />
                <Checkbox value="ContactPersonFirstName" label="Förnamn kontaktperson" />
                <Checkbox value="ContactPersonLastName" label="Efternamn kontaktperson" />
              </Stack>
            </Checkbox.Group>

            <Checkbox.Group label="Ansökan" value={formFields} onChange={setFormFields}>
              <Stack gap={8} pt={8}>
                {getElements().map((x) => {
                  return (
                    <Checkbox
                      key={x.id}
                      value={x.id}
                      label={
                        x.label.find((y) => y.language === LanguageCode.sv)?.text ??
                        '(Rubrik saknas)'
                      }
                    />
                  );
                })}
              </Stack>
            </Checkbox.Group>
          </Stack>
        </Stack>
      </Box>
      <Group justify={'end'} pt={'xl'}>
        <Button onClick={() => exportApplications()}>Exportera till Excel</Button>
      </Group>
    </Flex>
  );
};
