import React, { useEffect, useState } from 'react';
import { Button, Select, Stack, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useSession } from '../../hooks/useSession';
import { useApi } from '../../hooks/useApi';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { DisplayButtons } from '../../components/LiitGrid/DisplayButtons';
import { Criteria } from '../../models/Criteria';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { t } from 'ttag';
import { RenderWeightCell } from './RenderWeightCell';
import { LiitFormContainer } from '../../components/LiitFormContainer/LiitFormContainer';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';

interface FormValues {
  id: string;
  title: string;
  weightSelectValue: string | null;
}

export const SettingsCriteriasView: React.FC = () => {
  const api = useApi();
  const [criterias, setCriterias] = useState<Criteria[] | undefined>(undefined);
  const { selectedOrganisation } = useSession();
  const [formOpened, setFormOpened] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const form = useForm<FormValues>({
    initialValues: { id: '', title: '', weightSelectValue: '100' },
    transformValues: (values) => ({
      ...values,
      weight: Number(values.weightSelectValue),
    }),
  });

  const refreshCriterias = async () => {
    if (!selectedOrganisation) {
      return;
    }

    setIsFetching(true);
    const result = await api.getCriterias(selectedOrganisation.id);

    setCriterias(result);
    setIsFetching(false);
  };

  const createCriteria = async () => {
    form.setFieldValue('id', '');
    form.setFieldValue('title', '');
    form.setFieldValue('weightValue', '100');
    setFormOpened(true);
  };

  const editCriteria = async (id: string) => {
    if (!criterias) {
      return;
    }

    form.setFieldValue('id', id);

    const criteria = criterias.find((x) => x.id === id);
    if (criteria) {
      form.setFieldValue('title', criteria.title);
      form.setFieldValue('weightSelectValue', criteria.weight.toString());
    }

    setFormOpened(true);
  };

  const deleteCriteria = async (id: string) => {
    if (!selectedOrganisation) {
      return;
    }

    const response = await api.deleteCriteria(selectedOrganisation.id, id);
    if (response.ok) {
      await refreshCriterias();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await refreshCriterias();
    };

    fetchData();
  }, [selectedOrganisation]);

  const saveCriteria = async (values) => {
    const typeValues = values as Criteria;
    if (!selectedOrganisation) {
      return;
    }

    const isNew = !values.id;

    const response = isNew
      ? await api.createCriteria(selectedOrganisation.id, typeValues.title, typeValues.weight)
      : await api.updateCriteria(
        selectedOrganisation.id,
        typeValues.id,
        typeValues.title,
        typeValues.weight,
      );

    if (response.ok) {
      await refreshCriterias();
      setFormOpened(false);
      const title = isNew ? t`Kriterium skapat` : t`Kriterium uppdaterat`;
      const message = isNew
        ? t`Kriterium ${typeValues.title} har skapats`
        : t`Kriterium ${typeValues.title} har uppdaterats`;
      showSuccessNotification(title, message);
      return;
    }

    showFailNotification(t`Något gick fel`, t`Något gick fel vid sparning, var god försök senare`);
  };

  const displayColumns: IDisplayColumn[] = [
    {
      displayName: t`Namn`,
      propertyName: 'title',
      propertyType: PropertyType.Text,
      columnStyles: { width: '50%', cursor: 'pointer', paddingLeft: 40 },
    },
    {
      displayName: t`Räknas till rank/medel`,
      propertyName: 'weight',
      renderCell: RenderWeightCell,
      propertyType: PropertyType.Text,
      columnStyles: { width: '1%', cursor: 'pointer', userSelect: 'none', whiteSpace: 'nowrap' },
    },
  ];

  const gridButtons: DisplayButtons[] = [
    { name: 'edit', icon: <IconEdit size={24} />, onClick: (id: string) => editCriteria(id) },
    { name: 'remove', icon: <IconTrash size={24} />, onClick: (id: string) => deleteCriteria(id) },
  ];

  return (
    <GrantMeContainer>
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Bedömningskriterier`}</Title>
          <Button size={'sm'} onClick={() => createCriteria()} leftSection={<IconPlus />}>
            {t`Nytt bedömningskriterie`}
          </Button>
        </GrantMeTitleContainer>

        <LiitGrid
          data={criterias}
          columnInfo={displayColumns}
          displayButtons={gridButtons}
          fetchingData={isFetching}
          onRowClick={(id) => editCriteria(id)}
        />
      </Stack>
      <LiitDrawer
        titleText={form.values.id ? 'Redigera kriteria' : 'Skapa nytt kriteria'}
        opened={formOpened}
        size="md"
        padding="lg"
        onClose={() => {
          setFormOpened(false);
        }}
        withCloseButton>
        <form
          onSubmit={form.onSubmit(async (values) => saveCriteria(values))}
          style={{ width: '100%', display: 'flex' }}>
          <LiitFormContainer
            confirmText={t`Spara`}
            onCancel={() => {
              setFormOpened(false);
            }}>
            <Stack>
              <TextInput label={t`Namn`} {...form.getInputProps('title')} maxLength={20} />
              <Select
                label={t`Räknas till rank/medel`}
                {...form.getInputProps('weightSelectValue')}
                data={[
                  { value: '100', label: t`Ja` },
                  { value: '0', label: t`Nej` },
                ]}></Select>
            </Stack>
          </LiitFormContainer>
        </form>
      </LiitDrawer>
    </GrantMeContainer>
  );
};
