import React, { ReactNode } from 'react';
import { Group, Popover, Text, Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
interface TextWithPopoverProps {
  cellText: string | number | null;
  popoverContent: ReactNode;
  children?: ReactNode;
  onOpen?: () => void;
}

export const TextWithPopover: React.FC<TextWithPopoverProps> = ({
  cellText,
  popoverContent,
  children,
  onOpen,
}) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Group gap={'xs'} justify={'end'} align={'center'}>
      <Popover position="right" withArrow shadow="md" opened={opened}>
        <Popover.Target>
          <Box onMouseEnter={() => { open(); if (onOpen) { onOpen(); } }} onMouseLeave={close}>
            <Text size={'sm'}>{cellText}</Text>
          </Box>
        </Popover.Target>
        <Popover.Dropdown p={0}> 
          {popoverContent}
        </Popover.Dropdown>
        {children}
      </Popover>
    </Group>
  );
};
