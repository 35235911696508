import { Text } from '@mantine/core';
import { FC } from 'react';
import Formatter from '../../../../utils/formatter';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElement } from '../../models/FormElement';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';

export const ViewerDatePickerElement: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const { value, labelAndTextPosition } = element as FormElement;
  const text = value as string;
  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text ta={labelAndTextPosition ?? 'left'} size="sm" style={{ whiteSpace: 'pre-wrap' }}>{Formatter.formatDateString(text)}</Text>
    </ViewerInputWrapper>
  );
};
