import { Text, Image, Card, ActionIcon, Paper, Stack, Tooltip } from '@mantine/core';
import { FC } from 'react';
import { isMissing } from 'utilitype';
import { useSession } from '../../../../hooks/useSession';
import { UploadedFile } from '../../../../models/UploadedFile';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { SessionStorageKeys } from '../../../LiitDrawer/LiitDrawer';
import { FormElement } from '../../models/FormElement';
import { IconFileTypePdf } from '@tabler/icons-react';
import { useSessionStorage } from '@mantine/hooks';
import { AttachmentListItem } from '../../../../models/AttachmentListItem';
import { AttachmentType } from '../../../../models/AttachmentType';
import { AttachmentDisplayFormat } from '../../../../models/AttachmentDisplayFormat';

const isPdf = (type: string): boolean => type === 'application/pdf';
const PLACEHOLDER_IMAGE = '/img-placeholder.jpg';

export const ViewerFileBox: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const fileElement = element.value as UploadedFile;
  const { labelAndTextPosition } = element as FormElement;

  const [, setActiveAttachment] = useSessionStorage<string>({
    key: SessionStorageKeys.ActiveImage,
  });

  const [attachments] = useSessionStorage<AttachmentListItem[]>({
    key: SessionStorageKeys.ApplicationAttachments,
  });

  const { selectedOrganisation } = useSession();

  if (isMissing(fileElement)) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        <Text ta={labelAndTextPosition ?? 'left'} size="sm">
          {'Ingen fil uppladdad'}
        </Text>
      </ViewerInputWrapper>
    );
  }

  const { id, type } = fileElement;

  const getThumbnailUrl = () => {
    if (isMissing(attachments)) {
      return;
    }

    const item = attachments.find((x) => x.id == id);

    if (item?.type === AttachmentType.Image) {
      const thumbnailItem = item.urls.find((x) => x.format === AttachmentDisplayFormat.Thumbnail);
      return thumbnailItem?.url ?? PLACEHOLDER_IMAGE;
    }

    return;
  };


  const getImage = () => {
    const url = getThumbnailUrl();
    if (url) {
      return <Image
        style={{ cursor: 'pointer' }}
        src={url}
        w="auto"
        fit="contain"
        onClick={() => {
          setActiveAttachment(fileElement.id as string);
        }} />;
    } else {
      return <Image
        style={{ cursor: 'pointer' }}
        //withPlaceholder
        height={80}
        width={120}
        fit="contain"
        onClick={() => {
          setActiveAttachment(fileElement.id as string);
        }} />;
    }

  };

  const getDisplayElement = () => {
    if (isPdf(type)) {
      return (
        <div
          style={{
            paddingTop: '8px',
            width: 120,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Card shadow={'sm'} withBorder>
            <ActionIcon
              size={64}
              variant={'subtle'}
              onClick={() => setActiveAttachment(fileElement.id as string)}>
              <IconFileTypePdf size={48} />
            </ActionIcon>
          </Card>
          <Text truncate pt={2} size={'xs'} w={'inherit'} ta='center'>
            <Tooltip withArrow label={fileElement.filename} position="top-start">
              <span>{fileElement.filename}</span>
            </Tooltip>
          </Text>
        </div>
      );
    } else {
      return (
        <Stack gap={0} align='center' w={120}>
          <Paper withBorder p={4}>
            {getImage()}
          </Paper>
          <Text maw={120} truncate size={'xs'} w={'inherit'} ta='center'>
            <Tooltip withArrow label={fileElement.filename} position="top-start">
              <span>{fileElement.filename}</span>
            </Tooltip>
          </Text>
        </Stack>
      );
    }
  };

  if (id && selectedOrganisation) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        {getDisplayElement()}
      </ViewerInputWrapper>
    );
  }

  return <></>;
};
