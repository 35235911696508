import { Anchor, Center, Loader, Space, Title, Text, Table } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { isMissing } from 'utilitype';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { Application } from '../../../models/Application';
import { Link } from 'react-router-dom';
import Formatter from '../../../utils/formatter';
import { IconReport } from '@tabler/icons-react';
import { HoverTooltip } from '../../../components/HoverTooltip';

interface ApplicationToolbarItemHistoryProps {
  application: Application;
  isSelected: boolean;
}

export const ApplicationToolbarItemHistory: React.FC<ApplicationToolbarItemHistoryProps> = ({
  application,
  isSelected,
}) => {
  const { selectedOrganisation } = useSession();
  const [history, setHistory] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const api = useApi();

  useEffect(() => {
    const fetch = async () => {
      if (isMissing(selectedOrganisation)) {
        return;
      }

      setLoading(true);
      const result = await api.getHistoryByApplication(selectedOrganisation.id, application.id);
      setHistory(result);
      setLoading(false);
    };

    if (isSelected && application) {
      fetch();
    }

  }, [isSelected, application]);

  const hasApplications = () => {
    return history && ((history.approvedApplications && history.approvedApplications.length > 0) ||
      (history.declinedApplications && history.declinedApplications.length > 0) ||
      (history.submittedApplications && history.submittedApplications.length > 0) ||
      (history.deletedApplications && history.deletedApplications.length > 0));
  };

  const getGrantedAmount = (year: number | null) => {
    const apps = year ? history.approvedApplications.filter(x => x.grantedYear === year) : history.approvedApplications;
    return apps.reduce((partialSum, app) => partialSum + app.grantedAmount, 0);
  };

  const getApprovedApplicationsCount = (year: number | null) => {
    const apps = year ? history.approvedApplications.filter(x => x.grantedYear === year) : history.approvedApplications;
    return apps.length;
  };

  const getApplicationsTable = (title, applications, showAmount: boolean) => {

    if (!applications || applications.length === 0 || applications.filter(x => x.id !== application.id).length === 0)
      return <></>;

    return <>
      <Space h={'md'} />

      <Title order={6} pb={'xs'}>{title}</Title>

      <Table p={0} horizontalSpacing={4} verticalSpacing={'xs'} fz={'xs'}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Diarienr</Table.Th>
            <Table.Th>Ändamål</Table.Th>
            {showAmount && <Table.Th>Belopp</Table.Th>}
            {showAmount && <Table.Th></Table.Th>}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {applications.filter(x => x.id !== application.id).map((app) => (
            <Table.Tr key={'history_' + app.id}>
              <Table.Td style={{ whiteSpace: 'nowrap', verticalAlign: 'top', width: 70 }}>
                {app.canView && (
                  <Anchor fz={'xs'} component={Link} to={`/applications/${app.id}`}>
                    {app.number}
                  </Anchor>
                )}
                {!app.canView && <>{app.number}</>}
              </Table.Td>
              <Table.Td><Text fz={'xs'} maw={showAmount ? 170 : 260} truncate>{app.title}</Text></Table.Td>
              {showAmount && <Table.Td>{Formatter.formatCurrency(app.grantedAmount)}</Table.Td>}
              {showAmount && <Table.Td>{!app.hasReports && <HoverTooltip content="Återrapport inlämnad."><IconReport size={16} color='green' /></HoverTooltip>}</Table.Td>}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </>;
  };

  if (loading) {
    return <Center><Loader /></Center>;
  }

  if (hasApplications()) {
    return (
      <>
        <Text pt={'sm'} size={'xs'}>Andra ansökningar av <b>{application.applicantName}</b>.</Text>
        <Space h={'md'} />

        <Table p={0} horizontalSpacing={4} verticalSpacing={'xs'} fz={'xs'}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th>Totalt</Table.Th>
              <Table.Th>{new Date().getFullYear()}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>Beviljat belopp:</Table.Td>
              <Table.Td>{Formatter.formatCurrency(getGrantedAmount(null))}</Table.Td>
              <Table.Td>{Formatter.formatCurrency(getGrantedAmount(new Date().getFullYear()))}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Beviljade ansökningar:</Table.Td>
              <Table.Td>{getApprovedApplicationsCount(null)}</Table.Td>
              <Table.Td>{getApprovedApplicationsCount(new Date().getFullYear())}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>

        {getApplicationsTable('Beviljade ansökningar', history.approvedApplications, true)}
        {getApplicationsTable('Nekade ansökningar', history.declinedApplications, false)}
        {getApplicationsTable('Inlämnade ansökningar', history.submittedApplications, false)}
        {getApplicationsTable('Borttagna ansökningar', history.deletedApplications, false)}
      </>
    );
  }

  return (
    <Center p={'lg'}>
      <Text size={'sm'}>
        Inga andra ansökningar har gjorts av <b>{application.applicantName}</b>.
      </Text>
    </Center>
  );
};