import React, { useEffect, useState } from 'react';
import { Button, Stack, Title, Text, Drawer } from '@mantine/core';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useSession } from '../../hooks/useSession';
import { useApi } from '../../hooks/useApi';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { DisplayButtons } from '../../components/LiitGrid/DisplayButtons';
import { OrganisationUser } from '../../models/OrganisationUser';
import { CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { isMissing, isPresent } from 'utilitype';
import { OrganisationUserForm } from './OrganisationUserForm';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { t } from 'ttag';
import { openConfirmModal } from '@mantine/modals';
import { Role } from '../../models/Role';

export const SettingsUsersView: React.FC = () => {
  const api = useApi();
  const [users, setUsers] = useState<OrganisationUser[] | undefined>(undefined);
  const { selectedOrganisation } = useSession();
  const [isFetching, setIsFetching] = useState(false);
  const [editUser, setEditUser] = useState<OrganisationUser | null>(null);

  const createUser = async () => {
    setEditUser({
      id: '',
      email: '',
      firstname: '',
      lastname: '',
      role: Role.Reviewer,
      isSelf: false,
      activeUtc: '',
    });
  };

  const refreshUsers = async () => {
    if (!selectedOrganisation) {
      return;
    }

    setIsFetching(true);
    const result = await api.getOrganisationUsers(selectedOrganisation.id);
    setUsers(result);
    setEditUser(null);
    setIsFetching(false);
  };

  const setEditOrganisationUser = async (id: string) => {
    if (!users) {
      return;
    }

    const userToEdit = users.find((user) => user.id === id);

    if (isMissing(userToEdit)) {
      return;
    }

    setEditUser(userToEdit);
  };

  const deleteOrganisationUser = async (id: string) => {
    if (!selectedOrganisation) {
      return;
    }

    const user = users?.find((x) => x.id == id);

    return openConfirmModal({
      title: <Title order={3}>{t`Ta bort användare`}</Title>,
      children: (
        <Text
          size={'sm'}>{t`Vill du ta bort användaren ${user?.firstname} ${user?.lastname}?`}</Text>
      ),
      labels: { confirm: 'Ta bort', cancel: 'Avbryt' },
      onConfirm: async () => {
        await api.deleteOrganisationUser(selectedOrganisation.id, id);
        refreshUsers();
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await refreshUsers();
    };

    fetchData();
  }, [selectedOrganisation]);

  const displayColumns: IDisplayColumn[] = [
    {
      displayName: 'Namn',
      propertyName: 'firstname',
      renderCell: (rowInformation: CellRenderInformation) =>
        `${rowInformation.rowValues.firstname} ${rowInformation.rowValues.lastname}`,
      propertyType: PropertyType.Text,
      columnStyles: { width: 'auto', paddingLeft: 40 },
    },
    { displayName: 'E-post', propertyName: 'email', propertyType: PropertyType.Text },
    {
      displayName: 'Roll',
      propertyName: 'role',
      renderCell: (rowInformation: CellRenderInformation) => {
        if (rowInformation.cellValue === Role.Administrator) return 'Administratör';
        else if (rowInformation.cellValue === Role.Approver) return 'Beslutsfattare';
        else if (rowInformation.cellValue === Role.Reviewer) return 'Granskare';
        else return '';
      },
      propertyType: PropertyType.Text,
    },
    {
      displayName: 'Senaste inloggning',
      propertyName: 'activeUtc',
      propertyType: PropertyType.DateTimeUtcToLocal,
    },
  ];

  const gridButtons: DisplayButtons[] = [
    {
      name: 'edit',
      icon: <IconEdit size={24} />,
      onClick: (id: string) => setEditOrganisationUser(id),
    },
    {
      name: 'remove',
      icon: <IconTrash size={24} />,
      onClick: (id: string) => deleteOrganisationUser(id),
    },
  ];

  return (
    <GrantMeContainer>
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Title order={3}>Användare</Title>

          <Button size={'sm'} onClick={() => createUser()} leftSection={<IconPlus />}>
            {t`Bjud in användare`}
          </Button>
        </GrantMeTitleContainer>
        <LiitGrid
          data={users}
          columnInfo={displayColumns}
          displayButtons={gridButtons}
          fetchingData={isFetching}
          onRowClick={(id) => setEditOrganisationUser(id)}
        />
      </Stack>
      <LiitDrawer
        titleText={editUser?.id ? 'Redigera användare' : 'Bjud in användare'}
        opened={isPresent(editUser)}
        size="md"
        padding="lg"
        onClose={() => {
          setEditUser(null);
        }}
        position="right"
        withCloseButton>
        <OrganisationUserForm onSave={refreshUsers} user={editUser} />
      </LiitDrawer>
    </GrantMeContainer>
  );
};
