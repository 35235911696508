import {
  Progress,
  Grid,
  Indicator,
  Badge,
  Text,
  Table,
  useMantineTheme,
  Group,
} from '@mantine/core';
import { IconReport, IconSearch } from '@tabler/icons-react';
import React from 'react';
import { Dashboard } from '../../../models/Dashboard';
import { DashboardCard } from './DashboardCard';
import { dashboardViewStyles } from '../DashboardViewStyles';
import { ReviewStatuses } from '../../application/common/ReviewStatuses';
import Formatter from '../../../utils/formatter';
import { useNavigate } from 'react-router-dom';
import { t } from 'ttag';

interface RecentReviewsCardProps {
  data: Dashboard;
}

export const RecentReviewsCard: React.FC<RecentReviewsCardProps> = ({ data }) => {
  const { classes } = dashboardViewStyles();
  const theme = useMantineTheme();
  const reviewStatuses = ReviewStatuses(theme);
  const navigate = useNavigate();

  const getContent = () => {
    if (data.mostRecentReviewedApplications.length === 0) {
      return <Text c="dimmed">{t`Du har inte granskat några ansökningar ännu.`}</Text>;
    }

    return (
      <Table verticalSpacing={'xs'} className={classes.dashboardTable}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t`Nr`}</Table.Th>
            <Table.Th>{t`Titel`}</Table.Th>
            <Table.Th>{t`Status`}</Table.Th>
            <Table.Th>
              <Group justify={'end'}>{t`Föreslaget belopp`}</Group>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.mostRecentReviewedApplications?.map((x) => {
            return (
              <React.Fragment key={'mrrapp_' + x.id}>
                <Table.Tr
                  onClick={() => {
                    navigate(`/applications/${x.id}`);
                  }}>
                  <Table.Td>
                    <Text fz={'sm'} fw={500}>
                      {x.number}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text fz={'sm'} c="gray.7">
                      {x.title}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Indicator
                      offset={-12}
                      color={reviewStatuses[x.reviewStatus].color}
                      position="middle-start">
                      <Text fz={'sm'} c="gray.7">
                        {reviewStatuses[x.reviewStatus].title}
                      </Text>
                    </Indicator>
                  </Table.Td>
                  <Table.Td>
                    <Group justify="end">
                      <Text fz={'sm'}>{Formatter.formatCurrency(x.suggestedAmount)}</Text>
                    </Group>
                  </Table.Td>
                </Table.Tr>
              </React.Fragment>
            );
          })}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <DashboardCard title={t`Dina senaste granskningar`} icon={IconSearch} content={getContent()} />
  );
};
